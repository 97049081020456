import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static targets = [
        'location',
        'taskDiv',
        'lateCounter',
        'unassignedCounter',
        'unassignedDiv',
        'lateDiv'
    ]

    filter() {
        const filterLocation = this.locationTarget.value
        if (filterLocation) {
            Array.from(this.taskDivTarget.querySelectorAll('.agenda_task_list_item')).forEach((task) => {
                if (task.getAttribute('data-root') == filterLocation) {
                    task.classList.remove('hidden')
                }
                else {
                    task.classList.add('hidden')    
                }
            })
        }
        else {
            Array.from(this.taskDivTarget.querySelectorAll('.agenda_task_list_item')).forEach((task) => {
            task.classList.remove('hidden')
            })
        }
        const lateCount = this.lateDivTarget.querySelectorAll('li:not(.hidden).agenda_task_list_item').length
        const unassignedCount = this.unassignedDivTarget.querySelectorAll('li:not(.hidden).agenda_task_list_item').length
        this.lateCounterTarget.innerText = lateCount
        this.unassignedCounterTarget.innerText = unassignedCount
    }
}
