import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../src/config/i18n.js'
export default class extends Controller {
  static targets = ['query', 'resultsDiv']

  search() {
    if (this.queryTarget.value.length > 2) {
      fetch(`/units/rep_search?q=${this.queryTarget.value}`, {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(data => {
          this.resultsDivTarget.innerHTML = ''
          data.response.results.forEach(line => {
            this.resultsDivTarget.insertAdjacentHTML('beforeend', `
          <a href="/units/${line.id}" >
            <li class="list-group-item list-group-item-action">
              <div class="card_content">
                <div class="card_title">
                ${line.name}
                </div>
                <div class="card_address simple_text">
                  <i class="fas fa-map-marker-alt"></i> ${line.full_address}
                </div>
              </div>
            </li>
          </a>
          `)
          })
        })
    }
    else
    {
      this.resultsDivTarget.innerHTML = ''
    }
  }

}


