import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['filters', 'button', 'icon']

  connect() {
    this.buttonTarget.addEventListener('click', () => this.toggleFilters())
  }

  toggleFilters() {
    if (this.filtersTarget.classList.contains('hide_mobile')) {
      this.filtersTarget.classList.add('show_mobile')
      this.filtersTarget.classList.remove('hide_mobile')
    } else if (this.filtersTarget.classList.contains('show_mobile')) {
      this.filtersTarget.classList.remove('show_mobile')
      this.filtersTarget.classList.add('hide_mobile')
    }
    this.iconTarget.classList.toggle('fa-arrow-up-wide-short')
    this.iconTarget.classList.toggle('fa-arrow-down-wide-short')
  }
}
