import { Controller } from "@hotwired/stimulus"
import moment from 'moment-timezone';
import { momentTimezone } from '@mobiscroll/javascript';
export default class extends Controller {
	static targets = ["userid", "unitid", "amenityid", "amenitytypeid", "userchoices", "daterange", "offset", "timelist", "hoursgraph"]

	initialize() {
		momentTimezone.moment = moment;
    this.cal = mobiscroll.datepicker("#date-picker", {
      display: 'inline',
      touchUi: true,
      controls: ['calendar','time'],
      stepMinute: 30,
      selectMultiple: false,
      dateFormat: 'DD/MM/YYYY',
      timeFormat: 'HH:mm',
			select: 'range',
      stepMinute: 30,
			showRangeLabels: true,
			firstDay: 1,
      dataTimezone: 'utc',
      displayTimezone: 'local',
      timezonePlugin: momentTimezone
    });
	}

	greet() {
		if (this.daterangeTarget.value && this.from && this.to) {

			this.getActivityData()
		}
	}

	get user_id() {
		// return the value of all options in the userchoicesTarget
		return this.useridTarget.value
	}

	get unit_id() {
		return this.unitidTarget.value
	}

	get amenity_id() {
		return this.amenityidTarget.value
	}

	get amenity_type_id() {
		return this.amenitytypeidTarget.value
	}

	get from() {
		// split the date range into two parts
		const dates = this.daterangeTarget.value.split('-')
		return dates[0]
	}

	get to() {
		const dates = this.daterangeTarget.value.split('-')
		return dates[1]
	}

	get offset() {
		return document.getElementById("timeoffset")
	}

	get timelist() {
		return this.timelistTarget
	}

	// create a function to make a request to the server to get the todo list for a user


	getActivityData() {

		fetch(`/companies/activity_data?from=${this.from}&to=${this.to}&offset=${this.offset.value}&user_id=${this.user_id}&unit_id=${this.unit_id}&amenity_id=${this.amenity_id}&amenity_type_id=${this.amenity_type_id}`, {
			method: 'GET',
			headers: { "Content-Type": "application/json" }
		})
			.then(response => response.json())
			.then(data => {
				this.hoursgraphTarget.innerHTML = ''
				let canvas = document.createElement('canvas')
				canvas.classList.add('chart')
				this.hoursgraphTarget.appendChild(canvas)
				let chart = new Chart(canvas, {
					type: 'bar',
					data: data,
					options: {
						scales: {
							y: {
								beginAtZero: true
							}
						}
					}
				})
			})
	}

	displayGraphs() {

		if (this.daterangeTarget.value) {
            this.getTaskList(this.taskTypeTarget.value, this.unitTarget.value, this.amenityTarget.value, this.amenityTypeTarget.value, this.from, this.to)
		}
	}

	get from() {
		// split the date range into two parts
		const dates = this.daterangeTarget.value.split('-')
		return dates[0]
	}

	get to() {
		const dates = this.daterangeTarget.value.split('-')
		return dates[1]
	}

	getTaskList(task_type, unit, amenity, amenity_type, from, to) {
        fetch(`/task_list?task_type=${task_type}&unit=${unit}&amenity=${amenity}&amenity_type=${amenity_type}&from=${from}&to=${to}`,
			{
				method: 'GET',
				headers: { "Content-Type": "application/json" }
			})
			.then(response => response.json())
			.then(data => {
				this.showTaskList(data)
            /* this.showTaskGraph(task_list)
            this.showCumulativeTaskGraph(task_list)
            this.showCumulativeCostGraph(task_list)
            this.showCumulativeTimeGraph(task_list) */
			})
    }

	showTaskList(task_list) {
		this.taskListTarget.innerHTML = ""
		task_list.forEach(line => {
			let row = document.createElement('tr')
			let td1 = document.createElement('td')
			let td2 = document.createElement('td')
			let td3 = document.createElement('td')
			let td4 = document.createElement('td')
			let td5 = document.createElement('td')
			let td6 = document.createElement('td')
			let td7 = document.createElement('td')
			// NOOOO
			td1.innerHTML = line.start_date
			td2.innerHTML = line.task_number
			td2.classList.add(...line.logoclass.split(' '))
			td3.innerHTML = line.title
			td4.innerHTML = line.unit_name
			td5.innerHTML = line.amenity_name
			td6.innerHTML = line.amenity_type_name
			td7.innerHTML = line.total_cost.cents / 100

			row.appendChild(td1)
			row.appendChild(td2)
			row.appendChild(td3)
			row.appendChild(td4)
			row.appendChild(td5)
			row.appendChild(td6)
			row.appendChild(td7)

			this.taskListTarget.appendChild(row)
		}

		)
	}


	getHoursGraph(userid) {
		// fetch the todo list for the user
		fetch(`/users/${userid.slice(5)}/get_hours_graph?from=${this.from}&to=${this.to}`,
			{
				method: 'GET',
				headers: { "Content-Type": "application/json" }
			})
			.then(response => response.json())
			.then(data => {
				// clear the todo list
				this.hoursgraphTarget.innerHTML = ''
				let canvas = document.createElement('canvas')
				canvas.classList.add('chart')
				this.hoursgraphTarget.appendChild(canvas)
				let chart = new Chart(canvas, {
					type: 'bar',
					data: data,
					options: {
						scales: {
							y: {
								beginAtZero: true
							}
						}
					}
				})
			}
			)
	}
}


