import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../src/config/i18n.js'
export default class extends Controller {
  static targets = ['button']

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  sendInstructions() {
    if (this.buttonTarget.dataset.disabled) return
    fetch(this.buttonTarget.dataset.url,
      {
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          this.buttonTarget.classList.add('btn-success')
          this.buttonTarget.classList.remove('btn-outline-primary')
          this.buttonTarget.style.removeProperty('cursor')
          this.buttonTarget.innerText = `${i18n.t('user.password_reset_sent')}`
          this.buttonTarget.dataset.disabled = true
        }
      })
  }
}
