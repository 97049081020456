import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['page']

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  connect() {
    fetch('/users/update_preferences.json', {
      method:  'PATCH',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        preferences: {
          dashboard: {
            graph: this.pageTarget.dataset.page
          }
        }
      })
    })
  }
}
