import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['list', 'filters']

  filter(event) {
    this.listTarget.querySelectorAll('li.hidden').forEach((li) => {
      li.classList.remove('hidden')
    })
    event.target.classList.toggle('active')
    if (event.target.classList.contains('active')) {
      event.target.style.borderColor = `${event.target.dataset.color}`
    } else {
      event.target.style.borderColor = `${event.target.dataset.color}66`
    }
    let active_filters_values = Array.from(this.filtersTarget.querySelectorAll('.active')).map((filter) => filter.dataset.label)
    active_filters_values.forEach((filter) => {
      this.listTarget.querySelectorAll('li:not(.hidden)').forEach((li) => {
        if (!(li.dataset.labels.includes(filter))) {
          li.classList.add('hidden')
        }
      })
    })
  }

}
