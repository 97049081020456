import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = ['id']

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  get mailParameterId() {
    return this.idTarget.dataset.id
  }

  toggle(event) {
    const button = event.target.nodeName == 'I' ? event.target.parentElement : event.target
    const parameter = button.dataset.parameter
    const value = button.dataset.value
    document.querySelectorAll(`[data-parameter="${parameter}"]`).forEach((element) => {

      if (element.dataset.value == value) {
        element.classList.add('active')
      }
      else
      {
        element.classList.remove('active')
      }

    })

    fetch(`/mail_parameters/${this.mailParameterId}`, {
      method:  'PATCH',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.csrfToken },
      body:    JSON.stringify({ mail_parameter: { [parameter]: value } })
    })

  }
}
