import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['responsible_div', 'selector', 'prefiltering_div']

  connect() {
    this.responsible_mode_changed()
  }

  responsible_mode_changed() {
    console.log(this.selectorTarget.value)
    if (this.selectorTarget.value == 'responsible') {
      this.responsible_divTarget.classList.remove('hidden')
      this.responsible_divTarget.querySelector('select').required = true
    } else {
      this.responsible_divTarget.classList.add('hidden')
      this.responsible_divTarget.querySelector('select').required = false
    }
  }


}
