import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['table']

  startDrag(e) {
    this.sourceRow = e.target
  }

  enterRow(e) {
    e.preventDefault()
    e.target.closest('th').classList.add('border-start')
    e.target.closest('th').classList.add('border-primary-subtle')
  }

  leaveRow(e) {
    e.target.closest('th').classList.remove('border-start')
    e.target.closest('th').classList.remove('border-primary-subtle')
  }

  dropRow(e) {
    e.preventDefault()
    this.targetRow = e.target.closest('th')
    this.targetRow.classList.remove('border-start')
    this.targetRow.classList.remove('border-primary-subtle')

    if (!this.validRequest()) { return }

    fetch('/table_displays/update_default_column_order', {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      method: 'PATCH',
      body:   JSON.stringify({
        source_index: this.sourceIndex,
        target_index: this.targetIndex,
        table_name:   this.tableName
      })
    })
      .then(response => response.json())
      .then(data => {
        window.location.replace(data.redirect)
      })
  }

  validRequest() {
    if (this.sourceIndex === this.targetIndex) { return false }
    if (this.targetIndex === '0')              { return false }
    return true
  }

  set sourceRow(row) { this._sourceRow = row  }
  get sourceRow()    { return this._sourceRow }

  set targetRow(row) { this._targetRow = row  }
  get targetRow()    { return this._targetRow }

  get sourceIndex()  { return this.sourceRow.dataset.index }
  get targetIndex()  { return this.targetRow.dataset.index }

  get tableName()    { return this.tableTarget.dataset.tableName }

  get csrfToken()    { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

}
