import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'selectTypeBtn',
    'createTypeBtn',
    'typeSelection',
    'typeCreation',
    'typeSelectionInput',
    'typeCreationInput',
    'autoRenewal',
    'renewalDurationInput'
  ]

  connect() {
    if (this.typeSelectionInputTarget.value !== '') { this.typeSelectionTarget.classList.remove('hidden') }
    this.checkSubmit()
    this.checkAutoRenew()
  }

  get submitBtn() {
    return document.getElementById('submit-button')
  }

  revealTypeSelection() {
    this.typeCreationInputTarget.value = ''
    this.typeSelectionTarget.classList.remove('hidden')
    this.selectTypeBtnTarget.classList.remove('btn-outline-primary')
    this.selectTypeBtnTarget.classList.add('btn-primary')

    this.typeCreationTarget.classList.add('hidden')
    this.createTypeBtnTarget.classList.add('btn-outline-primary')
    this.createTypeBtnTarget.classList.remove('btn-primary')
  }

  revealTypeCreation() {
    this.typeSelectionInputTarget.value = ''
    this.typeSelectionTarget.classList.add('hidden')
    this.selectTypeBtnTarget.classList.add('btn-outline-primary')
    this.selectTypeBtnTarget.classList.remove('btn-primary')

    this.typeCreationTarget.classList.remove('hidden')
    this.createTypeBtnTarget.classList.remove('btn-outline-primary')
    this.createTypeBtnTarget.classList.add('btn-primary')
  }

  checkSubmit() {
    const formSubmitable = this.checkTypeValuePresence()
    if (formSubmitable) {
      this.submitBtn.disabled = false
    } else {
      this.submitBtn.disabled = true
    }
  }

  checkTypeValuePresence() {
    if (this.typeSelectionInputTarget.value !== '') { return true }
    if (this.typeCreationInputTarget.value !== '')  { return true }
    return false
  }

  checkAutoRenew() {
    if (this.autoRenewalTarget.checked) {
      this.renewalDurationInputTarget.classList.remove('hidden')
    } else {
      this.renewalDurationInputTarget.classList.add('hidden')
    }
  }
}
