import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  

  connect() {
    document.getElementById('drop-task').querySelectorAll('button.btn-close').forEach((elem) => {
      elem.onclick = function() {
        window.calendar.refetchEvents()
        window.calendar.render()
        // location.reload()
      }
    })
  }

  cancel(event) {
    document.getElementById('drop-task').querySelectorAll('button.btn-close').forEach((elem) => {
      elem.click()
    })
    // location.reload()
    window.calendar.getEventById(event.target.dataset.eventid).remove()
  }

  submit() {
    document.getElementById('drop-task').querySelectorAll('button.btn-close').forEach((elem) => {
      elem.click()
    })
  }
} 