// Sentry Initialization
import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn:          'https://2052724d9d78c32a586b2227619eab46@o570099.ingest.sentry.io/4506621488136192',
  environment:  window.env,
  enabled:      window.env === 'production' || window.env === 'staging',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText:   true,
      blockAllMedia: true,
    })
  ]
})

// Localization
import { I18n } from 'i18n-js'
import translations from '../src/config/translations.json'

// Fetch user locale from html#lang.
// This value is being set on `app/views/layouts/application.html.erb` and
// is inferred from `ACCEPT-LANGUAGE` header.
window.i18n = new I18n()
window.i18n.store(translations)
window.i18n.defaultLocale = 'en'
window.i18n.enableFallback = true

import Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
Rails.start()
require('turbolinks').start()
require('@rails/activestorage').start()


// To remove when stop using recurring_select & fullcalendar
import 'jquery'
import $ from 'jquery'
window.jQuery = $
window.$ = $
require('jquery')
import '../../../vendor/javascripts/recurring_select.js.erb'

// import 'chart.js'
// import Choices from 'choices.js'
// import moment from 'moment-timezone'
// import ModuleLoader from './abstracts/_moduleLoader'
// ModuleLoader


// Stimulus initialization
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

window.Stimulus = Application.start()
const context = require.context('../controllers', true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

// React on Rails initialization
import './react-bundle.js'

// Bootstrap tooltips
import 'bootstrap'
import { Tooltip } from 'bootstrap'

document.addEventListener('turbolinks:load', () => {
  // Both of these are from the Bootstrap 5 docs
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
})
