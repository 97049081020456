import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = [
    'userids',
    'employeeids',
    'dates',
    'addBtn'
  ]

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  assign(event) {
    var userids = Array.from(this.useridsTarget.options).map(option => option.value)
    var employeeids =  Array.from(this.employeeidsTarget.options).map(option => option.value)
    var dates = this.datesTarget.value
    var todo_id = event.target.dataset.todo
    var addBtn = this.addBtnTarget
    var closeBtn = addBtn.closest('.modal').querySelector('.btn-close')
    fetch(`/add_days_to_todo.json?userids=${userids}&employeeids=${employeeids}&dates=${dates}&to_do_item_id=${todo_id}`, {
      method:  'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
      .then(response => response.json())
      .then(() => {
        
        if (closeBtn) {
          closeBtn.click()
        }
        if (window.calendar) {
          window.calendar.refetchEvents()
        }
      })
  }
}