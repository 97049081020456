import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['responsible', 'type', 'unit', 'status']

  filter() {
    window.location.assign(`/${this.url}`)
  }

  get url() {
    return `?report_type_id=${this.type_value}&unit_id=${this.unit_value}&responsible_id=${this.responsible_value}&status=${this.status_value}`
  }

  get type_value() {
    return this.typeTarget.value
  }

  get unit_value() {
    return this.unitTarget.value
  }

  get responsible_value() {
    return this.responsibleTarget.value
  }

  get status_value() {
    return this.statusTarget.value
  }

}
