import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["accessField", "usersField"]

  connect() {
    if (this.accessFieldTarget.checked === true) {
      this.usersFieldTarget.classList.remove('hidden')
    }
  }

  toggleUsersField() {
    if (this.accessFieldTarget.checked === true) {
      this.usersFieldTarget.classList.remove('hidden')
    } else {
      this.usersFieldTarget.classList.add('hidden')
    }
  }
}
