import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['list']

  addMaterialToAmenity(event) {
    const materialId = event.currentTarget.dataset.materialId
    this.triggerFetch(materialId, this.amenityId)
  }

  triggerFetch(materialId, amenityId) {
    const data = {
      material_to_amenity: {
        material_id: materialId,
        amenity_id:  amenityId
      }
    }
    fetch('/material_to_amenities.json', {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      method: 'POST',
      body:   JSON.stringify(data)
    }).then(response => response.json())
      .then((data) => {
        this.listTarget.insertAdjacentHTML('afterbegin', `
            <li class="list-group-item list-group-item-action">
              <div class="card_content">
                <div class="card_title">
                  ${data.material.name}
                </div>
                <div class="card_address simple_text">
                  ${data.material.brand}
                </div>
              </div>
              <div class="btn-container">
                <a rel="nofollow" data-method="delete" class="btn btn-outline-danger" href="/material_to_amenities/${data.material_to_amenity.id}">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </div>
            </li>
          `
        )
      })
  }

  get amenityId() { return this.listTarget.dataset.amenityId }
  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

}
