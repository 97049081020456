import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'emailStep',
    'passwordStep',
    'email',
    'password',
    'spinner',
    'showEmail',
    'error',
    'accessLocked'
  ]

  timeout = null

  // Check login strategy for company of user
  verifyEmail() {
    if (!this.emailTarget.value) { return }
    if (!this.emailTarget.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      this.showError(true)
      this.showSpinner(false)
      return
    }


    if (this.timeout) {
      this.showSpinner(true)
      return
    }

    this.timeout = setTimeout(() => {
      this.showSpinner(false)
      this.timeout = null
    }, 1500)

    this.showSpinner(true)
    this.showError(false)

    fetch(`/users/check_email?email=${this.emailTarget.value}`)
      .then(response => response.json())
      .then(strategy => {
        if (strategy?.error) this.showError(true)
        else if (strategy?.access_locked) this.showAccessLocked(true)
        else if (strategy?.can_create_account) this.showAccountCreation(strategy)
        else this.showLoginStrategy(strategy)
      })
  }

  // Show email step (back from password)
  showEmail() {
    this.passwordStepTarget.hidden = true
    this.emailStepTarget.hidden = false
    this.showSpinner(false)
  }

  // Go to next step if hitting enter on email field
  checkIfEnter(event) {
    if (event.keyCode === 13 && this.passwordStepTarget.hidden) {
      event.preventDefault()
      this.verifyEmail()
    }
  }

  // Show error field
  showError(bool) {
    this.errorTarget.hidden = !bool
    this.showSpinner(false)
  }

  showAccessLocked(bool) {
    this.accessLockedTarget.hidden = !bool
    this.showSpinner(false)
    if (document.getElementById('default_alert')) {
      document.getElementById('default_alert').hidden = true
    }
  }

  showSpinner(bool) {
    this.spinnerTarget.hidden = !bool
  }

  showLoginStrategy(strategy) {
    switch (strategy.method) {
      case 'azure': window.location = `${window.location.toString()}?company=${strategy.subdomain}`; break
      case 'password': this.showPassword(); break
      default: this.showPassword()
    }
  }

  showPassword() {
    this.passwordStepTarget.hidden = false
    this.emailStepTarget.hidden = true
    this.showSpinner(false)
    this.passwordTarget.focus()
  }

  showAccountCreation(strategy) {
    window.location = `/users/sign_up?email=${this.emailTarget.value}&company_id=${strategy.company}&preferred_locale=${strategy.preferred_locale}`
  }
}
