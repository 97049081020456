import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar", "button", "nav", "list", "calendar"]

  connect() {
    this.buttonTarget.addEventListener('click', () => this.toggleSidebar())
  }

  toggleSidebar() {
    this.updateButtonContent()
    this.sidebarTarget.classList.toggle('col-3')
    this.sidebarTarget.classList.toggle('col-1')
    this.calendarTarget.classList.toggle('col-sm-9')
    this.calendarTarget.classList.toggle('col-sm-11')
    this.navTarget.classList.toggle('hidden')
    this.listTarget.classList.toggle('hidden')
    this.sidebarTarget.dataset.expanded = this.sidebarTarget.dataset.expanded === "0"
                                          ? "1"
                                          : "0"
  }

  updateButtonContent() {
    this.buttonTarget.innerHTML = this.sidebarTarget.dataset.expanded === "0"
                                  ? `<i class="fa-solid fa-angles-right pe-2" data-fullcalendar--toggle-sidebar-target="icon"></i>${window.i18n.t('actions.hide')}`
                                  : `<i class="fa-solid fa-angles-left pe-2" data-fullcalendar--toggle-sidebar-target="icon"></i>${window.i18n.t('actions.show')}`
  }
}
