import Choices        from 'choices.js'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modelType', 'fieldsList']

  showCustomFields() {
    fetch(`/${this.modelType}/${this.modelTypeTarget.value}/custom_fields`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        this.fieldsListTarget.innerHTML = ''
        for (const [key, values] of Object.entries(data)) {
          if (key !== '') {
            this.fieldsListTarget.insertAdjacentHTML('beforeend', `<h6>${key}</h6>`)
          }
          if (values !== null) {
            values.forEach(field => {
              let classes = ''
              if (key !== '') { classes = 'ms-3' }
              if (field.reply_type === 'multiple_choice') { classes = `${classes} js-choice-container-large` }

              var newInputDiv = `
                <div class="form-group ${classes}">
                  <label class="form-control-label">
                    ${field.name}
                  </label>
                </div>
              `
              this.fieldsListTarget.insertAdjacentHTML('beforeend', newInputDiv)

              switch (field.reply_type) {
                case 'free_text':
                  this.createFreeTextField(field)
                  break
                case 'multiple_choice':
                  this.createMultipleChoiceField(field)
                  break
                case 'true_false':
                  this.createBooleanField(field)
                  break
                case 'date':
                  this.createDateField(field)
                  break
                case 'date_time':
                  this.createDateTimeField(field)
                  break
                case 'number':
                  this.createNumberField(field)
                  break
              }
            })
          }
        }
      })
  }

  createFreeTextField(field) {
    const newTextField = `
      <input class="form-control" type="text" id="field-${field.id}" name="field-${field.id}">
    `
    this.fieldsListTarget.lastElementChild.insertAdjacentHTML('beforeend', newTextField)
  }

  createMultipleChoiceField(field) {
    const newMultipleChoiceField = `
      <select id="field-${field.id}" name="field-${field.id}">
        <option value="">${window.i18n.t('shared.none')}</option>
      </select>
    `
    this.fieldsListTarget.lastElementChild.insertAdjacentHTML('beforeend', newMultipleChoiceField)

    const selectChoices = []
    field.choice.forEach((option) => {
      selectChoices.push({
        value: option,
        label: option
      })
    })

    const selector = document.getElementById(`field-${field.id}`)
    const choice   = new Choices(selector, {
      resetScrollPosition: false,
      removeItemButton:    true,
      itemSelectText:      '',
      allowHTML:           true,
      searchResultLimit:   10
    })

    choice.setChoices(
      selectChoices,
      'value',
      'label',
      false
    )
  }

  createBooleanField(field) {
    const newBooleanField = `
      <h6 class="switch">
        <label class="switch switch-flat">
          <input class="switch-input" type="checkbox" id="field-${field.id}" name="field-${field.id}" value="true">
          <span class="switch-label" data-on="yes" data-off="no"></span>
          <span class="switch-handle"></span>
        </label>
      </h6>
    `
    this.fieldsListTarget.lastElementChild.insertAdjacentHTML('beforeend', newBooleanField)
  }

  createDateField(field) {
    const newDateField = `
      <input class="form-control" id="field-${field.id}" name="field-${field.id}" type="date">
    `
    this.fieldsListTarget.lastElementChild.insertAdjacentHTML('beforeend', newDateField)
  }

  createDateTimeField(field) {
    const newDateTimeField = `
      <input class="form-control" id="field-${field.id}" name="field-${field.id}" type="datetime-local">
    `
    this.fieldsListTarget.lastElementChild.insertAdjacentHTML('beforeend', newDateTimeField)
  }

  createNumberField(field) {
    const newNumberField = `
      <input class="form-control" onwheel="this.blur()" type="number" step="0.01" id="field-${field.id}" name="field-${field.id}">
    `
    this.fieldsListTarget.lastElementChild.insertAdjacentHTML('beforeend', newNumberField)
  }

  get modelType() { return this.modelTypeTarget.dataset.pluralizedModelType }

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

}
