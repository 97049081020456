import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  filter() {
    const search = this.element.querySelector('input').value
    fetch(`${this.element.dataset.fetch}?search=${search}`, {
      headers: { Accept: 'text/vnd.turbo-stream.html' }
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  clear() {
    this.element.querySelector('input').value = ''
    fetch(this.element.dataset.fetch, {
      headers: { Accept: 'text/vnd.turbo-stream.html' }
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
