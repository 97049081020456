import Choices        from 'choices.js'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'addBtn',
    'btnProviders',
    'btnTechnicians',
    'date',
    'notify',
    'providerid',
    'providers',
    'providerUsers',
    'technicians',
    'userid'
  ]

  showTechnicians() {
    this.techniciansTarget.classList.remove('hidden')
    this.providersTarget.classList.add('hidden')

    this.useridTarget.dataset.usethis = true
    this.provideridTarget.dataset.usethis = false

    this.btnTechniciansTarget.classList.remove('btn-outline-primary')
    this.btnTechniciansTarget.classList.add('btn-primary')
    this.btnProvidersTarget.classList.remove('btn-primary')
    this.btnProvidersTarget.classList.add('btn-outline-primary')

    this.useridTarget.required = true
    this.provideridTarget.required = false
  }

  showProviders() {
    this.providersTarget.classList.remove('hidden')
    this.techniciansTarget.classList.add('hidden')

    this.provideridTarget.dataset.usethis = true
    this.useridTarget.dataset.usethis = false

    this.btnProvidersTarget.classList.remove('btn-outline-primary')
    this.btnProvidersTarget.classList.add('btn-primary')
    this.btnTechniciansTarget.classList.remove('btn-primary')
    this.btnTechniciansTarget.classList.add('btn-outline-primary')

    this.useridTarget.required = false
    this.provideridTarget.required = true
  }

  // Create & populate the provider user selector
  showProviderUsers() {
    const providerid = this.provideridTarget.value

    fetch(`/service_providers/search_users.json?provider=${providerid}`)
      .then(response => response.json())
      .then(data => {
        const selectChoices = []
        data.response.results.forEach((result) => {
          selectChoices.push({
            value: result.id,
            label: result.name
          })
        })

        this.providerUsersTarget.innerHTML = ''
        this.buildUserSelector()

        const selector = document.getElementById('providerUserSelector')
        const providerUserSelector = new Choices(selector, {
          resetScrollPosition: false,
          removeItemButton:    true,
          itemSelectText:      '',
          allowHTML:           true,
          searchResultLimit:   10
        })
        selector.addEventListener('change', (e) => this.setProviderUserId(e))

        providerUserSelector.setChoices(
          selectChoices,
          'value',
          'label',
          false
        )
      })
  }

  //  Handle form submission
  assign(event) {
    let assignation = null
    let userid      = null

    if (this.useridTarget && this.useridTarget.dataset.usethis == 'true') {
      assignation = 'internal'
      userid      = this.useridTarget.value
    }

    if (this.provideridTarget && this.provideridTarget.dataset.usethis == 'true') {
      assignation = 'external'
      userid      = null
    }

    const addBtn         = this.addBtnTarget
    const closeBtn       = addBtn.closest('.modal').querySelector('.btn-close')
    const date           = this.dateTarget.value
    const maintenance_id = event.target.dataset.maintenance
    const notify         = this.notifyTarget.value
    const original_date  = event.target.dataset.date
    const task_id        = event.target.dataset.taskId

    fetch('/maintenances.json', {
      method:  'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({
        data: {
          auto_assign:                false,
          date:                       date,
          maintenance_id:             maintenance_id,
          notify:                     notify,
          original_date:              original_date,
          user:                       userid,
          [`task-${task_id}`]:        userid ? userid : this.providerUserId,
          [`assignation-${task_id}`]: assignation
        }
      })
    })
      .then(response => response.json())
      .then(() => {
        if (closeBtn) {
          closeBtn.click()
        }
        if (window.calendar) {
          window.calendar.refetchEvents()
        }
      })
  }

  // Build HTML base for the provider user selector
  buildUserSelector() {
    this.providerUsersTarget.innerHTML = `
      <div class="form-group select optional form-group-valid js-choice-container-large">
        <label class="form-control-label select optional">
          ${window.i18n.t('user.users')}
        </label>
        <select class="form-control select optional" id="providerUserSelector">
        </select>
      </div>
    `
  }

  // Save the provider user ID
  setProviderUserId(event) {
    this.providerUserId = event.currentTarget.value
  }

  set providerUserId(newId) { this._providerUserId = newId }
  get providerUserId()      { return this._providerUserId }

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

}
