import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'selectBtn',
    'createBtn',
    'selection',
    'creation',
    'selectionInput',
    'creationInput',
    'inputType'
  ]

  connect() {
    if (this.selectionInputTarget.value !== '') {
      this.selectionTarget.classList.remove('hidden')
      this.selectBtnTarget.classList.remove('btn-outline-primary')
      this.selectBtnTarget.classList.add('btn-primary')
    }
    this.checkSubmit()
  }

  revealSelection() {
    this.creationInputTarget.value = ''
    this.inputTypeTarget.value     = 'selection'
    this.selectionTarget.classList.remove('hidden')
    this.selectBtnTarget.classList.remove('btn-outline-primary')
    this.selectBtnTarget.classList.add('btn-primary')

    this.creationTarget.classList.add('hidden')
    this.createBtnTarget.classList.add('btn-outline-primary')
    this.createBtnTarget.classList.remove('btn-primary')

    this.checkSubmit()
  }

  revealCreation() {
    this.selectionInputTarget.value = ''
    this.inputTypeTarget.value      = 'creation'
    this.selectionTarget.classList.add('hidden')
    this.selectBtnTarget.classList.add('btn-outline-primary')
    this.selectBtnTarget.classList.remove('btn-primary')

    this.creationTarget.classList.remove('hidden')
    this.createBtnTarget.classList.remove('btn-outline-primary')
    this.createBtnTarget.classList.add('btn-primary')

    this.checkSubmit()
  }

  checkSubmit() {
    const formSubmitable    = this.checkValuePresence()
    this.submitBtn.disabled = !formSubmitable
  }

  checkValuePresence() {
    var check = false
    if (this.selectionInputTarget.value !== '') { check = true }
    if (this.creationInputTarget.value !== '')  { check = true }
    return check
  }

  get submitBtn() {
    return document.getElementById('submit-button')
  }

}
