import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = [
    'unit',
    'amenityType',
    'amenityList',
    'totalCount',
    'addAll',
    'paginate'
  ]

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  get contractId() { return this.amenityListTarget.getAttribute('data-contract')}

  connect() {
    this.getAmenities()
  }

  getAmenities(event) {
    var page_nb = ''
    if (event) {
      event.preventDefault()
      page_nb = event.target.dataset.page
    }
    fetch(`/contracts/${this.contractId}/get_unlinked_amenities.json?unit_id=${this.unitTarget.value}&amenity_type_id=${this.amenityTypeTarget.value}&page=${page_nb}`,
      {
        headers: {'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken },
        method: 'GET'
      }
    ).then(response => response.json())
      .then(data => {
        this.amenityListTarget.innerHTML = ''
        
        this.totalCountTarget.innerHTML = data.total_count
        this.paginateTarget.innerHTML = ''

        var pages_count = Math.ceil(data.total_count / 50)
        if (pages_count > 1)
        {
          var pages = []
          for (var i = 1; i <= pages_count; i++) {
            pages.push(i)
          }
          pages.forEach(page => {
            var li = document.createElement('li')
            li.classList.add('page-item')
            var div = document.createElement('div')
            div.classList.add("page-link")
            div.innerText = page
            if ((page == page_nb) || (page_nb == '' && page == 1))
            { li.classList.add('active') }
            if (page != page_nb)
            { 
              li.setAttribute('data-action', 'click->contracts--filter-amenities#getAmenities')
              li.setAttribute('data-page', page)
              div.setAttribute('data-action', 'click->contracts--filter-amenities#getAmenities')
              div.setAttribute('data-page', page)
            }
            li.appendChild(div)
            this.paginateTarget.appendChild(li)
          })
        }

        this.addAllTarget.setAttribute('data-ids', data.amenities.map(amenity => amenity.id).join(','))
        
        data.amenities.forEach(amenity => {
          var row = document.createElement('tr')
          var btn = document.createElement('td')
          var name = document.createElement('td')
          var amenity_type = document.createElement('td')
          var brand = document.createElement('td')
          var serial_number = document.createElement('td')
          var unit_name = document.createElement('td')

          btn.innerHTML = `<div class="btn btn-primary" data-action="click->contracts--filter-amenities#addToContract" data-id=${amenity.id}>Add</button>`
          name.innerHTML = amenity.name
          amenity_type.innerHTML = amenity.amenity_type_name
          brand.innerHTML = amenity.brand
          serial_number.innerHTML = amenity.serial_number
          unit_name.innerHTML = amenity.unit_name

          row.appendChild(btn)
          row.appendChild(name)
          row.appendChild(amenity_type)
          row.appendChild(brand)
          row.appendChild(serial_number)
          row.appendChild(unit_name)

          this.amenityListTarget.appendChild(row)

          

      })
    })
  }

  addToContract(event) {
    fetch(`/contract_to_amenities.json?`,
    {
      headers: {'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken },
      method: 'POST',
      body: JSON.stringify({ contract_to_amenity: { contract_id: this.contractId, amenity_id: event.target.dataset.id } })
    }
  ).then(response => response.json())
    .then(data => {
    
    
      const linkedAmenities = document.querySelector('#amenities_linked_to_contract')
      var row = document.createElement('tr')
      var name = document.createElement('td')
      var brand = document.createElement('td')
      var amenity_type = document.createElement('td')
      var unit = document.createElement('td')
      var btn = document.createElement('td')

      name.innerHTML = event.target.parentElement.parentElement.children[1].innerHTML
      brand.innerHTML = event.target.parentElement.parentElement.children[3].innerHTML
      amenity_type.innerHTML = event.target.parentElement.parentElement.children[2].innerHTML
      unit.innerHTML = event.target.parentElement.parentElement.children[5].innerHTML
      btn.innerHTML = `
      <a rel="nofollow" data-method="delete" href="/contract_to_amenities/${data.id}">
        <i class="far fa-trash-alt"></i>
      </a>`

      row.appendChild(name)
      row.appendChild(brand)
      row.appendChild(amenity_type)
      row.appendChild(unit)
      row.appendChild(btn)

      linkedAmenities.appendChild(row)

      document.getElementById('amenities-linked-table').classList.remove("hidden")
      document.getElementById('no-amenities-linked').classList.add("hidden")

      event.target.parentElement.parentElement.remove()
      this.totalCountTarget.innerHTML = parseInt(this.totalCountTarget.innerHTML) - 1
    })
  }

  addAllToContract() {
    fetch(`/contract_to_amenities.json?`,
    {
      headers: {'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken },
      method: 'POST',
      body: JSON.stringify({ contract_to_amenity: { contract_id: this.contractId, equipment_list: this.addAllTarget.dataset.ids } })
    }
  ).then(response => response.json())
    .then(data => {

      document.getElementById('amenities-linked-table').classList.remove("hidden")
      document.getElementById('no-amenities-linked').classList.add("hidden")
      window.location.reload()
    })
  }
}