import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../src/config/i18n.js'

export default class extends Controller {
  static targets = ['form', 'download', 'progress']

  duplicate() {
    var modelName = this.assetType
    var modelId   = this.formTarget.dataset.modelId
    fetch(`/${modelName}/${modelId}/duplicate.json`, {
      method:  'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.formParams)
    })
      .then(response => response.json())
      .then(data => {
        const jobId         = data.job.job_id
        const jobProviderId = data.job.job_provider_id
        this.progressTarget.textContent = `${i18n.t('shared.processing')}`
        this.progressTarget.classList.remove('hidden')
        this.formTarget.classList.add('hidden')
        this.timer = setInterval(() => {
          this.checkJobStatus(jobId, jobProviderId)
        }, 1000)
      })
  }

  checkJobStatus(jobId, jobProviderId) {
    fetch(`/export_status?job_id=${jobId}&job_provider_id=${jobProviderId}`)
      .then(response => response.json())
      .then(data => {
        const percentage = data.percentage
        this.progressTarget.textContent = `${i18n.t('shared.processing')} ${percentage}%`
        if (data.status == 'error') {
          this.stopCheckJobStatus()
        } else if (data.status === 'complete') {
          this.stopCheckJobStatus()
          this.progressTarget.innerHTML = `
            <p>
              ${i18n.t('shared.processing_completed')}
            </p>
            <a href='/${this.assetType}/${data.all_keys.model_id}' class="btn btn-primary">
              ${i18n.t('actions.see')}
            </a>
          `
        }
      })
  }

  stopCheckJobStatus() {
    if (this.timer) { clearInterval(this.timer) }
  }

  disconnect() {
    this.stopCheckJobStatus()
  }

  get assetType() {
    return this.formTarget.dataset.modelName
  }

  get formParams() {
    return {
      name:                document.getElementById('duplicate_name')?.value,
      internal_identifier: document.getElementById('duplicate_internal_identifier')?.value,
      serial_number:       document.getElementById('duplicate_serial_number')?.value,
      contracts:           this.convertBoolean(document.getElementById('duplicate_contracts')?.checked),
      materials:           this.convertBoolean(document.getElementById('duplicate_materials')?.checked),
      providers:           this.convertBoolean(document.getElementById('duplicate_providers')?.checked),
      measurments:         this.convertBoolean(document.getElementById('duplicate_measurments')?.checked),
      users:               this.convertBoolean(document.getElementById('duplicate_users')?.checked),
      surfaces:            this.convertBoolean(document.getElementById('duplicate_surfaces')?.checked),
      maintenances:        this.convertBoolean(document.getElementById('duplicate_maintenances')?.checked),
      children:            this.convertBoolean(document.getElementById('duplicate_children')?.checked),
    }
  }

  convertBoolean(bool) {
    return bool ? '1' : '0'
  }

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

}
