import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modalLink', 'formMaintenanceIds', 'duration']

  updateMaintenances(e) {
    const ids  = this.maintenanceIds.split(',')
    let newIds = []

    if (e.target.checked) {
      ids.push(e.target.value)
      newIds = ids.filter((n) => n)
    } else {
      newIds = ids.filter((id) => id !== e.target.value)
    }

    this.maintenanceIds = newIds.toString()
    this.modalLinkTarget.innerHTML = newIds.length > 0 ? this.linkToModal : ''
  }

  deleteMaintenance(e) {
    const maintenanceId = e.target.parentNode.dataset.id
    const ids           = this.formMaintenanceIdsTarget.value.split(',')
    const newIds        = ids.filter((id) => id !== maintenanceId)

    this.formMaintenanceIdsTarget.value = newIds
    e.target.parentNode.remove()
  }

  get linkToModal() {
    return `
      <a  href="/openModal?maintenance_ids=${this.maintenanceIds}&div=plan-maintenance-content&modal=maintenances%2Fpartials%2Fplan_maintenance&modal_id=plan-maintenance-btn"
          class="hover-pointer"
          data-remote="true">
        <div class="btn btn-primary">${window.i18n.t('maintenance_control.actions.assign_to_one')}</div>
      </a>
    `
  }

  get maintenanceIds()       { return this.modalLinkTarget.dataset.maintenanceIds }
  set maintenanceIds(newIds) { this.modalLinkTarget.setAttribute('data-maintenance-ids', newIds) }

}
