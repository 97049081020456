import { Controller } from "@hotwired/stimulus"
import mapboxgl from '!mapbox-gl';
export default class extends Controller {
  connect() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    
    
    navigator.geolocation.getCurrentPosition(this.success, this.error, options);
    
    
  }

  success(pos) {
    var crd = {longitude: 0, latitude: 0};
    if (document.querySelector('#measurment_value_value').value != '')
      {
        const existing = document.querySelector('#measurment_value_value').value.split(',');
        crd.latitude = existing[0];
        crd.longitude = existing[1];
        document.querySelector('#measurment_value_value').value = crd.latitude + ',' + crd.longitude;
      }
    else 
      {
        if (pos)
        {
          crd = pos.coords; 
          document.querySelector('#measurment_value_value').value = crd.latitude.toFixed(5) + ',' + crd.longitude.toFixed(5);
      
        }
      }


    mapboxgl.accessToken = 'pk.eyJ1IjoiYmVydHJhbmRtb3JlbCIsImEiOiJjbGN1Z2o5a3cwbmk1M3dxdG91azJjZmZxIn0.mKd_D3yQCWmGnLC6TAvITw';
    var map = new mapboxgl.Map({
      container: 'map',
      center: [crd.longitude, crd.latitude],
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      zoom: 15
    });
    map.on('load', function () {
      map.resize();
    });
    map.resize();

  // make a marker for each feature and add to the map
    const marker = new mapboxgl.Marker({draggable: true}).setLngLat([crd.longitude, crd.latitude]).addTo(map);
    
    function onDragEnd(){
      const lngLat = marker.getLngLat();
      document.querySelector('#measurment_value_value').value = lngLat.lat.toFixed(5) + ',' + lngLat.lng.toFixed(5);
    }

    marker.on('dragend', onDragEnd);
    map.resize();
  }


  error(err) {  
    console.warn(`ERROR(${err.code}): ${err.message}`);

  }


}