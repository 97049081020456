import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["line"];

  get csrfToken() { return document.head.querySelector("meta[name='csrf-token']").getAttribute("content") }

  delete() {

    fetch(`/allocations/${this.lineTarget.dataset.id}`,
			{
				method: 'DELETE',
				headers: {
					"Accept": "application/json",
          "X-CSRF-Token": this.csrfToken
				}
			})
    .then( 
      this.lineTarget.remove()
      )
  };
}
