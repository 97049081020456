import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../src/config/i18n.js'

export default class extends Controller {
  static targets = ['line', 'lineDiv', 'editDiv', 'editBtn', 'deleteBtn', 'range', 'allDay', 'userids', 'doer', 'endedat', 'comment']

  get csrfToken() { return document.head.querySelector('meta[name=\'csrf-token\']').getAttribute('content') }

  get PeriodId() {
    return this.lineTarget.dataset.ptt
  }

  get Start() {
    return this.lineTarget.dataset.start
  }

  get Stop() {
    if (this.lineTarget.dataset.stop == null) {
      return this.lineTarget.dataset.start
    }
    return this.lineTarget.dataset.stop
  }

  get commentRequired() {
    const value = this.commentTarget.dataset.commentRequired
    return !(value == '0' || value == 'false')
  }


  showEdit() {
    this.lineTarget.classList.add('bg-light')
    this.lineTarget.dataset.action = 'click->todos--period-to-todo#hideEdit'
    this.allDayRange()
  }

  hideEdit() {
    this.lineTarget.classList.remove('bg-light')
    this.lineTarget.dataset.action = 'click->todos--period-to-todo#showEdit'
    this.editDivTarget.innerHTML = ''
  }

  update() {
    fetch(`/period_to_todos/${this.PeriodId}`,
      {
        method:  'PATCH',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        body: JSON.stringify({
          period_to_todo: {
            start:   this.rangeTarget.value.split(' - ')[0],
            stop:    this.rangeTarget.value.split(' - ')[1],
            all_day: this.allDayTarget.value
          }
        })
      })
      .then((data) => {
        return data.json()
      })
      .then((data) => {
        this.lineTarget.dataset.start = data.period_to_todo.start
        this.lineTarget.dataset.stop = data.period_to_todo.stop
        this.lineTarget.innerText = data.date

        this.hideEdit()

      })
  }

  doneAndApprove(event) {
    console.log('done_and_approve')

    const doer     = this.doerTarget.value
    const ended_at = this.endedatTarget.value
    const comment  = this.commentTarget.value
    const todo_id  = event.currentTarget.dataset.todo

    if (this.commentRequired && !comment) { return alert(i18n.t('todo.error.must_have_closing_comment'))}

    fetch(`/to_do_items/${todo_id}/approve_from_calendar`,
      {
        method:  'PATCH',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        body: JSON.stringify({
          doer:     doer,
          ended_at: ended_at,
          comment:  comment
        })
      })
      .then((data) => {
        return data.json()
      })
      .then(() => {
        this.fetchAndClose(event)

      })
  }

  approve(event) {
    console.log('approve')

    var todo_id  = event.currentTarget.dataset.todo

    fetch(`/to_do_items/${todo_id}/approve_from_calendar`,
      {
        method:  'PATCH',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
      })
      .then((data) => {
        return data.json()
      })
      .then(() => {
        this.fetchAndClose(event)
      })
  }




  duplicate(event) {
    console.log('coucou')

    var user_ids = []
    console.log('userids', this.useridsTarget.options)
    user_ids = Array.from(this.useridsTarget.options).map(option => option.value)
      .join(',')
    console.log('user_ids', user_ids)

    fetch(`/period_to_todos/${this.PeriodId}/duplicate?user_ids=${user_ids}`,
      {
        method:  'POST',
        headers: {
          'Accept':       'application/json',
          'X-CSRF-Token': this.csrfToken
        }
      })
      .then((data) => {
        return data.json()
      })
      .then(() => {
        this.fetchAndClose(event)
      })
  }

  delete() {
    fetch(`/period_to_todos/${this.PeriodId}`,
      {
        method:  'DELETE',
        headers: {
          'Accept':       'application/json',
          'X-CSRF-Token': this.csrfToken
        }
      })
      .then(() => {
        this.lineDivTarget.remove()
        if (window.calendar) {
          window.calendar.refetchEvents()
        }
      })
  }

  allDayRange() {
    this.editDivTarget.innerHTML = ''
    this.editDivTarget.insertAdjacentHTML('afterbegin',`
      <div data-controller="launch-mobiscroll" data-ptt=${this.PeriodId}>
        <label for="all_day">${ i18n.t('todo.all_day') }? </label>
        <input id="all_day" data-todos--period-to-todo-target="allDay" type="checkbox" checked name="all_day" value="true" data-action="click->todos--period-to-todo#timeRange">
        <input id="date-picker" data-todos--period-to-todo-target="range" data-launch-mobiscroll-target="input" class="launch-mobiscroll" data-script="mobiscrollDateRange" name="dates", type="hidden", data-start="${this.start}" data-stop="${this.stop}">
        <button type="button" data-action="click->todos--period-to-todo#update" class="btn btn-primary">${ i18n.t('actions.save')}</button>
      </div>
    `)
  }

  timeRange() {
    this.editDivTarget.innerHTML = ''
    this.editDivTarget.insertAdjacentHTML('afterbegin',`
      <div data-controller="launch-mobiscroll" data-ptt=${this.PeriodId}>
        <label for="all_day">${ i18n.t('todo.all_day') }? </label>
        <input id="all_day" data-todos--period-to-todo-target="allDay" type="checkbox" name="all_day" value="false" data-action="click->todos--period-to-todo#allDayRange">
        <input id="date-picker" data-todos--period-to-todo-target="range" data-launch-mobiscroll-target="input" class="launch-mobiscroll" data-script="mobiscrollTimeRange" name="dates", type="hidden", data-start="${this.start}" data-stop="${this.stop}">
        <button type="button" data-action="click->todos--period-to-todo#update" class="btn btn-primary">${ i18n.t('actions.save')}</button>
      </div>
    `)
  }

  fetchAndClose(event) {
    if (window.calendar) {
      window.calendar.refetchEvents()
    }
    var closeBtn = event.target.closest('.modal').querySelector('.btn-close')
    if (closeBtn) {
      closeBtn.click()
    }
  }


}
