import { Controller } from "@hotwired/stimulus"
import { i18n } from '../../src/config/i18n.js'

export default class extends Controller {
  static targets = ["btn"]

  get csrfToken() { return document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content") }

  get materialId() {
    return this.btnTarget.dataset.material
  }

  get amenityId() {
    return this.btnTarget.dataset.amenity
  }

  get favDiv() {
    return document.getElementById('favourite_material_list')
  }


  addFavourite() {
    fetch(`/amenities/${this.amenityId}/add_material?material_id=${this.materialId}`, {
      method: 'PATCH',
      headers: { "Content-Type": "application/json", "X-CSRF-Token": this.csrfToken }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status == 'success') {
        this.btnTarget.classList.remove('far')
        this.btnTarget.classList.add('fas')
        var description = ''
        var brand = ''
        var material_category = ''
        var responsible = ''
        // var display_location = ''

        if (data.material.description)
        {
          description = `${i18n.t('shared.description')}: ${data.material.description}<br>`
        }
        if (data.material.brand)
        {
          brand = `${i18n.t('material.brand')}: ${data.material.brand}<br>`
        }
        if (data.material.material_category)
        {
          material_category = `${i18n.t('material.category')}: ${data.material_category}<br>`
        }
        if (data.responsible)
        {
          responsible = `<i class="fas fa-users-cog"></i> ${data.responsible}<br>`
        }
        // if (data.location != 'n/a')
        // {
        //   display_location = `<i class="fas fa-map-marker-alt"></i> ${data.location}<br>`
        // }



        this.favDiv.insertAdjacentHTML('afterbegin',
          `<div class='task_item mh-2 mb-2'
                style='width: 100%;border-style: solid; border-width: 0.05rem; border-color:#ebebeb; border-radius: 3px; background-color: rgba(0,118,186,0.05);'
                data-controller="materials--remove-favourite"
                data-materials--remove-favourite-target="card"
                data-material="${data.material.id}"
                data-amenity="${this.amenityId}">
            <div  class="d-flex justify-content-between" style='padding: 5px; font-weight: bold; color: black'>
              <a href="/materials/${data.material.id}" target="_blank" style='font-size: 11.5px; font-weight: bold; color: black'>
                ${data.material.name}
              </a>
              <i class="fas fa-trash-alt text-danger"
                  style="cursor: pointer;"
                  data-action="click->materials--remove-favourite#remove"></i>
            </div>

            <div class="simple_text" style='padding: 5px; color: #858585;'>
              <div style="margin-bottom: none !important; ">
                ${ description }
                ${ brand }
                ${ material_category }
                ${ responsible }
                ${i18n.t('material.available_quantity')}: ${data.material.quantity}

              </div>
            </div>
          </div>`
        )


      }

      else
        alert('Error')

    })

  }
}
