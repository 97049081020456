import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
	static targets = []

  connect() {
    this.attacheListener();
  }

  attacheListener() {
    this.element.addEventListener('click', (e) => {

      // e.preventDefault();
      if (e.target.hasAttribute('data-type') && e.target.classList.contains('nav-link')) {
        this.filterList(e.target.getAttribute('data-type'));
        this.element.querySelector('.active').classList.remove('active');
        e.target.classList.add('active');
      }
    })
  }

  filterList(filterValue) {
    this.element.querySelectorAll('.list-group-item').forEach(element => {
      if (element.getAttribute('data-type') !== filterValue && filterValue !== "all") {
        element.classList.add('hidden');
        element.setAttribute('aria-hidden', true);
      } else {
        element.classList.remove('hidden');
        element.setAttribute('aria-hidden', false);
      }
    });
  }

  goToToday() {
    document.getElementById('today').scrollIntoView({ behavior: "smooth", block: "center" });
  }
}
