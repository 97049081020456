import { Controller } from "@hotwired/stimulus"
import { i18n } from '../../src/config/i18n.js'
export default class extends Controller {
  static targets = ["download", "progress", "progressWrapper", "status", "stopType", "unit", "amenity", "restartedAt"];

  export() {
    const status = this.statusTarget.value;
    const stopTypeId = this.stopTypeTarget.value;
    const unitId = this.unitTarget.value;
    const amenityId = this.amenityTarget.value;
    const restartedAt = this.restartedAtTarget.value;
    fetch(`/machine_stops/export?status=${status || ''}&stop_type_id=${stopTypeId || ''}&unit_id=${unitId || ''}&amenity_id=${amenityId || ''}&restarted_at=${restartedAt || ''}`)
      .then(response => response.json())
      .then(data => {
        const jobId = data.job.job_id;
        const jobProviderId = data.job.job_provider_id;
        this.progressTarget.classList.remove("hidden");
        this.progressTarget.textContent = `${i18n.t('shared.processing')}`;
        this.downloadTarget.classList.add("hidden");
        this.timer = setInterval(() => {
          this.checkJobStatus(jobId, jobProviderId)
        }, 1000);
      });
  }

  checkJobStatus(jobId, jobProviderId) {
    fetch(`/export_status?job_id=${jobId}&job_provider_id=${jobProviderId}`)
      .then(response => response.json())
      .then(data => {
        const percentage = data.percentage;
        this.progressTarget.textContent = `${i18n.t('shared.processing')} ${percentage}%`;
        if (data.status == "error") {
            this.stopCheckJobStatus();
        } else if (data.status === "complete") {
          this.stopCheckJobStatus()
          this.progressTarget.textContent = `${i18n.t('shared.processing_completed')}`;
          this.progressTarget.insertAdjacentHTML('afterend', `${i18n.t('shared.file_sent_to_your_email_address')}`);
        }
      })
  }

  stopCheckJobStatus() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  disconnect() {
    this.stopCheckJobStatus();
  }
}
