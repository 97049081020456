import { Controller } from "@hotwired/stimulus"
import { i18n } from '../src/config/i18n.js'

export default class extends Controller {
  static targets = [
    'customFieldId',
    'amenityTypeList',
    'amenityTypeIds',
    // 'mandatory'
  ]

  get amenityTypeIds() { return Array.from(this.amenityTypeIdsTarget.querySelectorAll('option')).map((option) => option.value) }

  addLink() {
    fetch(`/custom_fields/${this.customFieldIdTarget.id}/add_link?am_type_ids=${this.amenityTypeIds}`,
      {
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        this.amenityTypeListTarget.innerHTML = ''
        data.forEach(amenityType => {
          const item = `
            <li class="list-group-item">
              ${amenityType.name}
              <div id=${amenityType.id} class="btn btn-outline-danger hover-pointer" data-action="click->custom-field-to-amenity#removeLink">
                <i class="fas fa-trash me-1"></i>${i18n.t('actions.delete')}
              </div>
            </li>
          `
          this.amenityTypeListTarget.insertAdjacentHTML('beforeend', item)
        })
      })
  }

  removeLink(event) {
    const item = event.currentTarget.parentNode
    if (confirm(`${i18n.t('actions.are_you_sure')}`)) {
      fetch(`/custom_fields/${this.customFieldIdTarget.id}/remove_link?amenity_type=${event.currentTarget.id}`,
        {
          method:  'DELETE',
          headers: {
            'Accept':       'application/json',
            'X-CSRF-Token': this.csrfToken
          }
        })
        .then(response => response.json())
        .then(_ => item.remove())
    }
  }

  get csrfToken() { return document.head.querySelector("meta[name='csrf-token']").getAttribute("content") }

}
