import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['reportlist']

  getreports() {
    if ((this.amenityId === null || this.amenityId === '') && (this.unitId === null || this.unitId === '')) {
      this.reportlist.innerHTML = ''
      return
    }
    if (this.reportlist) {
      fetch(`/reports/get_existing_reports?amenity_id=${this.amenityId}&unit_id=${this.unitId}`,
        {
          method:  'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(response => response.json())
        .then(data => {
          this.reportlist.innerHTML = ''
          data.forEach(report => {
            this.reportlist.insertAdjacentHTML('beforeend', `
                <a href="/reports/${report.id}", target="_blank", class: "list-group-item-content">
                  <div  class="task_item w-100 border border-rounded rounded-1 p-2 pb-0 mb-1"
                        style="border-color:#ebebeb; background-color: #FAFAFA">
                    <div class="fw-bold text-black p-1">
                      ${report.title}
                    </div>
                    <div class="simple_text p-1 pb-0 mb-0" style="color: #858585;">
                      <i class="far fa-calendar-alt"></i> ${report.created_at} |
                      ${report.expertise ? `<i class='fas fa-flask'></i> ${report.expertise}<br>`     : ''}
                      ${report.unit      ? `<i class='fas fa-map-marker-alt'></i> ${report.unit}<br>` : ''}
                      ${report.amenity   ? `<i class='fas fa-cogs'></i> ${report.amenity}<br>`        : ''}
                      <i class="fas fa-user-alt"></i> ${report.username}<br>
                      <i class="fas fa-users-cog"></i> ${report.responsible}<br>
                      ${report.description}
                    </div>
                  </div>
                </a>
              `
            )
          })
        })
    }
  }

  setUnitId(event) {
    this.unitId = event.target.value
    this.getreports()
  }

  setAmenityId(event) {
    this.amenityId = event.target.value
    this.getreports()
  }

  get unitId() {
    return this._unitId
  }

  set unitId(newId) {
    this._unitId = newId
  }

  get amenityId() {
    return this._amenityId
  }

  set amenityId(newId) {
    this._amenityId = newId
  }

  get reportlist() {
    return this.reportlistTarget
  }

}
