import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = [
    'taskType',
    'reportFields',
    'maintenanceFields'
  ]

  initialize() {
}
  
  showtasktype() {
    if (this.taskTypeTarget.value === 'maintenance') {
      this.maintenanceFieldsTarget.classList.remove('hidden')
      this.reportFieldsTarget.classList.add('hidden')
    } else {
      this.maintenanceFieldsTarget.classList.add('hidden')
      this.reportFieldsTarget.classList.remove('hidden')
    }
  }











}