import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['report_type', 'fields']

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  get report_type_id() { return this.report_typeTarget.dataset.reportType }


  switch() {
    var old_value = this.report_typeTarget.dataset.active
    if (old_value == 'Y') {
      var new_value = false
      var active = 'N'
      this.fieldsTarget.classList.add('hidden')
    } else {
      var new_value = true
      var active = 'Y'
      this.fieldsTarget.classList.remove('hidden')
    }
    this.report_typeTarget.dataset.active = active

    fetch(`/report_types/${this.report_type_id}`, {
      headers: {
        'Accept':       'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({
        report_type: {
          active: new_value
        }
      }),
      method: 'PATCH',
    })
      .then(response => response.json())
      .then(data => {

      })
  }
}
