import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["object"]

  selectPic(e) {
    let document_id = e.target.getAttribute('data-doc-id')
    fetch(`/${this.controllerName}/${this.objectId}/set_profile_pic?profile_pic_id=${document_id}`,
    {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF_Token': this.csrfToken
      }
    })
    .then(response => response.json())
    .then(data => {
      this.updateDocumentList(data)
    })
  }

  removePic() {
    fetch(`/${this.controllerName}/${this.objectId}/set_profile_pic`,
    {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF_Token': this.csrfToken
      }
    })
    .then(response => response.json())
    .then(data => {
      this.updateDocumentList(data)
    })
  }

  updateDocumentList(data) {
    let previous_pic = document.getElementById(`object_${data.previous_pic_id}`)
    let new_pic      = document.getElementById(`object_${data.new_pic_id}`)
    if (previous_pic) {
      previous_pic.querySelector('.fav-btn').innerHTML = `<i class="fa-regular fa-heart pointer" data-action="click->profile-pic#selectPic" data-doc-id="${data.previous_pic_id}}"></i>`
    }
    if (new_pic) {
      new_pic.querySelector('.fav-btn').innerHTML = `<i class="fa-solid fa-heart pointer" data-action="click->profile-pic#removePic" data-doc-id="${data.new_pic_id}}"></i>`
    }
  }

  get controllerName() {
    return this.objectTarget.dataset.controllerName
  }

  get objectId() {
    return this.objectTarget.dataset.objectId
  }

  get csrfToken() {
    return document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content")
  }
}
