import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../src/config/i18n.js'

export default class extends Controller {

  startDrag(e) {
    this.sourceRow = e.target
    this.rowType   = this.sourceAmenityId ? 'amenity' : 'unit'
  }

  enterRow(e) {
    e.preventDefault()
    e.target.closest('tr').classList.add('drag-bg')
  }

  leaveRow(e) {
    e.target.closest('tr').classList.remove('drag-bg')
  }

  dropRow(e) {
    e.preventDefault()
    this.targetRow = e.target.closest('tr')
    this.targetRow.classList.remove('drag-bg')

    if (!this.validRequest()) { return }

    if (this.sourceAmenityId) {
      fetch(`/amenities/${this.sourceAmenityId}/update_ancestry`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        method: 'POST',
        body:   JSON.stringify({ parent_amenity_id: this.targetAmenityId, parent_unit_id: this.targetUnitId })
      })
        .then(response => response.json())
        .then((data) => {
          if (data.errors)                   { return alert(data.errors) }
          if (data.response.parent !== null) { this.removeParentToggle(data.response.parent) }

          const newRow = this.treeStructureController.buildAmenityRow(data.response.amenity)
          this.updateView(newRow)
        })
    } else if (this.sourceUnitId) {
      fetch(`/units/${this.sourceUnitId}/update_ancestry`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        method: 'POST',
        body:   JSON.stringify({ parent_unit_id: this.targetUnitId })
      })
        .then(response => response.json())
        .then((data) => {
          if (data.errors)                   { return alert(data.errors) }
          if (data.response.parent !== null) { this.removeParentToggle(data.response.parent) }

          const newRow = this.treeStructureController.buildUnitRow(data.response.unit)
          this.updateView(newRow)
        })
    }
  }

  validRequest() {
    if (this.sourceAmenityId !== undefined && this.sourceAmenityId === this.targetAmenityId) {
      return false
    }
    if (!this.sourceAmenityId && (this.sourceUnitId === this.targetUnitId)) {
      return false
    }
    if (!this.sourceAmenityId && this.targetAmenityId) {
      alert(i18n.t('shared.you_are_not_allowed_to_do_that_action'))
      return false
    }
    if (!confirm(i18n.t('actions.are_you_sure'))) { return false }

    return true
  }

  removeParentToggle(parent) {
    const parentToggle = document.getElementById(`${parent.type}-${parent.id}`).querySelector('.unit-list-collapse')
    parentToggle.classList.remove('hover-pointer')
    parentToggle.classList.add('text-white')
  }

  updateView(newRow) {
    this.sourceRow.remove()
    this.targetRow.insertAdjacentHTML('afterend', newRow)
    if (this.targetRow.querySelector('.unit-list-collapse') && this.targetRow.querySelector('.unit-list-collapse').classList.contains('text-white')) {
      this.targetRow.querySelector('.unit-list-collapse').remove()
      this.targetRow.querySelector('td > div').insertAdjacentHTML('afterbegin', this.treeToggle())
    }
    if (this.targetRow.querySelector('.fa-angle-right')) {
      this.targetRow.querySelector('.unit-list-collapse').click()
    }
  }

  treeToggle() {
    return `
      <i  class="fa fa-angle-down fa-xl hover-pointer pe-2 unit-list-collapse"
          data-asset-trees--structure-target="developBtn"
          data-action="click->asset-trees--structure#${this.rowType === 'amenity' ? 'hideAmenityChildren' : 'hideUnitChildren'}"></i>
    `
  }

  set sourceRow(row)    { this._sourceRow = row }
  set targetRow(row)    { this._targetRow = row }
  set rowType(type)     { this._rowType = type  }

  get sourceRow()       { return this._sourceRow }
  get targetRow()       { return this._targetRow }
  get rowType()         { return this._rowType   }

  get sourceAmenityId() { return this.sourceRow.dataset.amenity }
  get sourceUnitId()    { return this.sourceRow.dataset.unit    }

  get targetAmenityId() { return this.targetRow.dataset.amenity }
  get targetUnitId()    { return this.targetRow.dataset.unit    }

  get csrfToken()       { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  get treeStructureController() {
    return this.application.getControllerForElementAndIdentifier(this.targetRow, 'asset-trees--structure')
  }

}
