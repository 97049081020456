import { Controller } from '@hotwired/stimulus'
// import mobiscroll from '@mobiscroll/javascript'
import DateTime from 'datatables.net-datetime'
import moment from 'moment-timezone'
import { momentTimezone } from '@mobiscroll/javascript'
export default class extends Controller {
  static targets = ['input']

  connect() {
    var method = this.inputTarget.dataset.script
    switch (method) {
      case 'mobiscrollDate':
        this.mobiscrollDate(this.inputTarget)
        break
      case 'mobiscrollDateClick':
        this.mobiscrollDateClick(this.inputTarget)
        break
      case 'mobiscrollDateTime':
        this.mobiscrollDateTime(this.inputTarget)
        break
      case 'mobiscrollDateRange':
        this.mobiscrollDateRange(this.inputTarget)
        break
      case 'mobiscrollTimeRange':
        this.mobiscrollTimeRange(this.inputTarget)
        break
      case 'mobiscrollDateTimeFull':
        this.mobiscrollDateTimeFull(this.inputTarget)
        break
      case 'mobiscrollTimeRangeClick':
        this.mobiscrollTimeRangeClick(this.inputTarget)
        break
      case 'mobiscrollDateRangeClick':
        this.mobiscrollDateRangeClick(this.inputTarget)
        break
      default:
    }
  }

  show() {
    var method = this.inputTarget.dataset.script
    switch (method) {
      case 'mobiscrollDate':
        this.mobiscrollDate(this.inputTarget)
        break
      case 'mobiscrollDateClick':
        this.mobiscrollDateClick(this.inputTarget)
        break
      case 'mobiscrollDateTime':
        this.mobiscrollDateTime(this.inputTarget)
        break
      case 'mobiscrollDateRange':
        this.mobiscrollDateRange(this.inputTarget)
        break
      case 'mobiscrollTimeRange':
        this.mobiscrollTimeRange(this.inputTarget)
        break
      case 'mobiscrollDateTimeFull':
        this.mobiscrollDateTimeFull(this.inputTarget)
        break
      case 'mobiscrollTimeRangeClick':
        this.mobiscrollTimeRangeClick(this.inputTarget)
        break
      case 'mobiscrollDateRangeClick':
        this.mobiscrollDateRangeClick(this.inputTarget)
        break
      default:
    }
  }

  mobiscrollDate(elem) {
    var cal = mobiscroll.datepicker(elem, {
      locale:           mobiscroll.locale[document.documentElement.lang],
      display:          'inline',
      touchUi:          true,
      controls:         ['calendar'],
      selectMultiple:   false,
      dateFormat:       'DD/MM/YYYY',
      returnFormat:     'iso8601',
      firstDay:         1,
      defaultSelection: new Date(elem.dataset.start)
    })
  }

  mobiscrollDateTime(elem) {
    momentTimezone.moment = moment
    var cal = mobiscroll.datepicker(elem, {
      locale:           mobiscroll.locale[document.documentElement.lang],
      display:          'inline',
      touchUi:          true,
      controls:         ['datetime'],
      stepMinute:       10,
      selectMultiple:   false,
      dateFormat:       'DD/MM/YYYY',
      timeFormat:       'HH:mm',
      firstDay:         1,
      defaultSelection: new DateTime(elem.dataset.start),
      dataTimezone:     'utc',
      displayTimezone:  'local',
      timezonePlugin:   momentTimezone
    })
    this.element.querySelector(`#${elem.id}-timeoffset`).value = cal.getVal().getTimezoneOffset()
  }

  mobiscrollDateRange(elem) {
    var cal = mobiscroll.datepicker(elem, {
      locale:       mobiscroll.locale[document.documentElement.lang],
      display:      'inline',
      touchUi:      true,
      controls:     ['calendar'],
      select:       'range',
      dateFormat:   'DD/MM/YYYY',
      returnFormat: 'iso8601',
      firstDay:     1
    })
    cal.setVal([new Date(elem.dataset.start), new Date(elem.dataset.stop)])
  }

  mobiscrollTimeRange(elem) {
    var cal = mobiscroll.datepicker(elem, {
      locale:       mobiscroll.locale[document.documentElement.lang],
      display:      'inline',
      touchUi:      true,
      controls:     ['calendar', 'time'],
      select:       'range',
      timeFormat:   'HH:mm',
      dateFormat:   'DD/MM/YYYY',
      returnFormat: 'iso8601',
      firstDay:     1
    })
    if (elem.dataset.start && elem.dataset.stop) {
      cal.setVal([new Date(elem.dataset.start), new Date(elem.dataset.stop)])
    }
  }

  mobiscrollTimeRangeClick(elem) {
    var cal = mobiscroll.datepicker(elem, {
      locale:           mobiscroll.locale[document.documentElement.lang],
      touchUi:          true,
      controls:         ['calendar', 'time'],
      select:           'range',
      timeFormat:       'HH:mm',
      dateFormat:       'DD/MM/YYYY',
      returnFormat:     'iso8601',
      firstDay:         1,
      defaultSelection: [new Date(elem.dataset.defaultStart), new Date(elem.dataset.defaultStop)]
    })
    this.clear(cal)
    if (elem.dataset.start && elem.dataset.stop) {
      // expects ruby DateTime objects for start & stop
      cal.setVal([new Date(elem.dataset.start), new Date(elem.dataset.stop)])
    }
    if (elem.dataset.skipClear === 'true') { return }
    this.addClear()
  }

  mobiscrollDateRangeClick(elem) {
    var cal = mobiscroll.datepicker(elem, {
      locale:       mobiscroll.locale[document.documentElement.lang],
      touchUi:      true,
      controls:     ['calendar'],
      select:       'range',
      dateFormat:   'DD/MM/YYYY',
      returnFormat: 'iso8601',
      firstDay:     1
    })
    if (elem.dataset.start && elem.dataset.stop) {
      cal.setVal([new Date(elem.dataset.start), new Date(elem.dataset.stop)])
    }
    if (elem.dataset.skipClear === 'true') { return }
    this.addClear()
  }

  mobiscrollDateClick(elem) {
    var cal = mobiscroll.datepicker(elem, {
      locale:           mobiscroll.locale[document.documentElement.lang],
      touchUi:          true,
      controls:         ['calendar'],
      selectMultiple:   false,
      dateFormat:       'DD/MM/YYYY',
      returnFormat:     'iso8601',
      firstDay:         1,
      defaultSelection: new Date(elem.dataset.start)
    })
  }

  mobiscrollDateTimeFull(elem) {
    momentTimezone.moment = moment
    var cal = mobiscroll.datepicker(elem, {
      locale:           mobiscroll.locale[document.documentElement.lang],
      display:          'inline',
      touchUi:          true,
      controls:         ['datetime'],
      stepMinute:       1,
      selectMultiple:   false,
      dateFormat:       'DD/MM/YYYY',
      timeFormat:       'HH:mm',
      firstDay:         1,
      defaultSelection: new DateTime(elem.dataset.start),
      dataTimezone:     'utc',
      displayTimezone:  'local',
      timezonePlugin:   momentTimezone
    })
    this.element.querySelector(`#${elem.id}-timeoffset`).value = cal.getVal().getTimezoneOffset()
  }

  addClear() {
    this.inputTarget.insertAdjacentHTML('beforebegin', '<i class=\'clear_btn fas fa-times\' data-action="click->launch-mobiscroll#clear" style=\'margin-left: 5px; cursor: pointer;\'></i>')
  }

  clear(event) {
    this.inputTarget.value = ''
    var event = new Event('change')
    this.inputTarget.dispatchEvent(event)
  }

}
