import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = [
    "line"
  ]
  get csrfToken() { return document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content") }

  unlinkUser(event) {
    var user_id = this.lineTarget.dataset.user;
    var expertise_id = this.lineTarget.dataset.expertise;
    fetch (`/expertises/${expertise_id}/unlink_user.json?user_id=${user_id}`, {
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": this.csrfToken
            },
            method: "PATCH",
          })
          .then(response => response.json())
          .then(data => {
            this.lineTarget.remove()
          })
  }

  unlinkProvider(event) {
    var provider_id = this.lineTarget.dataset.provider;
    var expertise_id = this.lineTarget.dataset.expertise;
    fetch (`/expertises/${expertise_id}/unlink_provider.json?provider_id=${provider_id}`, {
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": this.csrfToken
            },
            method: "PATCH",
          })
          .then(response => response.json())
          .then(data => {
            this.lineTarget.remove()
          })
  }
}
