import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['row']

  updateMandatory(e) {
    const selectedValue = e.target.dataset.value

    fetch(`/report_types/${this.reportTypeId}/update_report_field_link.json`, {
      headers: {
        'Accept':       'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({
        link_id:   this.fieldLinkId,
        mandatory: selectedValue
      }),
      method: 'PATCH'
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors) { return alert(data.errors) }

        Array.from(this.rowTarget.querySelectorAll('td.report_type_choice')).forEach((element) => {
          const elementValue = element.dataset.value
          const label        = element.dataset.label || ''

          if (elementValue === selectedValue) {
            element.innerHTML = `<i class="fas fa-check-square text-primary me-1"></i> ${label}`
          } else {
            element.innerHTML = `<i class="far fa-square me-1" data-action='click->report-types--report-fields#updateMandatory' data-value=${elementValue}></i>${label}`
          }
        })
      })
  }

  get reportTypeId() { return this.rowTarget.dataset.reportTypeId }
  get fieldLinkId()  { return this.rowTarget.dataset.fieldLinkId  }
  get csrfToken()    { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }
}
