import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["assetId", "assetType", "unitDiv", "equipDiv"]

    connect() {
    }

    get csrfToken() { return document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content") }

    selectUnit(event){
        var unit_id = event.target.value
        if (unit_id != "") {
            this.assetIdTarget.value = unit_id
            this.assetTypeTarget.value = "Unit"
            while (event.target.parentElement.nextElementSibling) {
                event.target.parentElement.nextElementSibling.remove()
            }
        }
        else {
            if (event.target.parentElement.previousElementSibling){
                unit_id = event.target.parentElement.previousElementSibling.querySelector("select").value
                this.assetIdTarget.value = unit_id
                this.assetTypeTarget.value = "Unit"
                while (event.target.parentElement.nextElementSibling) {
                    event.target.parentElement.nextElementSibling.remove()
                }
                event.target.parentElement.remove()
            }
            else {
                this.assetIdTarget.value = ""
                this.assetTypeTarget.value = ""
                while (event.target.parentElement.nextElementSibling) {
                    event.target.parentElement.nextElementSibling.remove()
                }
            }
        }
        
        this.equipDivTarget.innerHTML = ""
        
        fetch(`/units/${unit_id}/children_units.json`, {
            headers: { "Content-Type": "application/json",
            "X-CSRF-Token": this.csrfToken }
        })
        .then(response => response.json())
        .then(data => {
            if (data != "" && data != null && data.length > 0) {
                this.unitDivTarget.insertAdjacentHTML('beforeend',
                `<div class="form-group select optional unit_lvl">
                    <select data-action="change->select-unit-equip#selectUnit" class="unit_select form-control select">
                    <option value="">Select Unit</option>
                    ${data.map(unit => `<option value="${unit.id}">${unit.name}</option>`)}
                    </select></div>`)
            }
            const unit_select_divs = Array.from(this.unitDivTarget.querySelectorAll(".unit_lvl"))
            unit_select_divs.forEach((selector) => {
                selector.style.marginLeft = `${unit_select_divs.indexOf(selector) }rem`
            })
            fetch(`/amenities.json?unit=${unit_id}&paginated=false&subtree=false&content=short`, {
                method: 'GET',
                headers: { "Content-Type": "application/json",
                "X-CSRF-Token": this.csrfToken }
            })
            .then(response => response.json())
            .then(data => {
                
                if (data != "" && data != null && data.length > 0) {
                    document.getElementById("equip_label").classList.remove('hidden')
                    this.equipDivTarget.insertAdjacentHTML('afterbegin',
                    `<div class="form-group select optional equip_lvl">
                        <select data-action="change->select-unit-equip#selectEquip" class="equip_select form-control select">
                        <option value="">Select Equipement</option>
                        ${data.map(amenity => `<option value="${amenity.id}">${amenity.name}</option>`)}
                        </select></div>`)
                }
                else {
                    document.getElementById("equip_label").classList.add('hidden')
                }
            })
        })
    }

    selectEquip(event){
        const equip_id = event.target.value
        if (equip_id != "") {
            this.assetIdTarget.value = equip_id
            this.assetTypeTarget.value = "Amenity"}
            else {
                if (event.target.parentElement.previousElementSibling){
                    this.assetIdTarget.value = event.target.parentElement.previousElementSibling.querySelector("select").value
                    this.assetTypeTarget.value = "Amenity"
                }
                else {
                    const unit_select_list = this.unitDivTarget.querySelectorAll("select")
                    const last_unit_div = unit_select_list[unit_select_list.length - 1]
                    if (last_unit_div.value != ""){
                        this.assetIdTarget.value = last_unit_div.value
                        this.assetTypeTarget.value = "Unit"
                    }
                    else {
                        if (last_unit_div.parentElement.previousElementSibling){
                            this.assetIdTarget.value = last_unit_div.parentElement.previousElementSibling.querySelector("select").value
                            this.assetTypeTarget.value = "Unit"
                        }
                        else {
                            this.assetIdTarget.value = ""
                            this.assetTypeTarget.value = ""
                        }
                    }
                }
            }
        while (event.target.parentElement.nextElementSibling) {
            event.target.parentElement.nextElementSibling.remove()
        }
        if (equip_id != "") {

        fetch(`/amenities/${equip_id}/children_amenities.json`, {
            method: 'GET',
            headers: { "Content-Type": "application/json",
            "X-CSRF-Token": this.csrfToken }
            })
            .then(response => response.json())
            .then(data => {
                
                if (data != "" && data != null && data.length > 0) {
                    this.equipDivTarget.insertAdjacentHTML('beforeend',
                    `<div class="form-group select optional equip_lvl">
                    <select data-action="change->select-unit-equip#selectEquip" class="equip_select form-control  select">
                        <option value="">Select Equipement</option>
                        ${data.map(amenity => `<option value="${amenity.id}">${amenity.name}</option>`)}
                        </select></div>`)
                }
                const equip_select_divs = Array.from(this.equipDivTarget.querySelectorAll(".equip_lvl"))
                equip_select_divs.forEach((selector) => {
                    selector.style.marginLeft = `${equip_select_divs.indexOf(selector) }rem`
                })
        })
    }
    }

    
}