import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
	static targets = ["controlList"]


	initialize() {
	}

	get template_id() {
		return this.controlListTarget.dataset.templateId
	}

  get csrfToken() { return document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content") }

  show(event) {
    event.preventDefault()
    const control = event.currentTarget.dataset.control
    this.controlListTarget.querySelectorAll('tr').forEach((element) => {
      element.classList.remove('tr-active-blue')
    })
    event.currentTarget.parentElement.parentElement.classList.add('tr-active-blue')
  }
}
