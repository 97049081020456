import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["inventoryDate"]

  toggleInventoryDate(e) {
    const quantityid = e.target.value
    if (quantityid === null || quantityid === '') {
      this.inventoryDateTarget.classList.add('hidden')
    } else {
      fetch(`/material_quantities/${quantityid}`,{
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          'X-CSRF_Token': $('meta[name="csrf-token"]').attr('content')
        }
      })
      .then(response => response.json())
      .then(data => {
        this.inventoryDateTarget.classList.remove('hidden')
        this.inventoryDateTarget.innerText = `${i18n.t('material.inventory_taken_on')}: ${data.inventory_date || 'n/a'}`
      })
    }
  }
}
