import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['title', 'spinner']

  /*
    This page can be reached AFTER triggering the PDF generation (ie: via report show page)
    Or BEFORE triggering the PDF generation (ie: via work_reports index page)
    In the first case, the pdfid dataset will have a value on connect
    In the second case, the pdfid dataset will be empty on connect
  */

  connect() {
    if (this.titleTarget.dataset.pdfid === '') {
      this.generateFullReport()
    } else {
      this.getPdfData()
    }
  }

  generateFullReport() {
    fetch(`/reports/${this.titleTarget.dataset.reportid}/generate_full_report`,
      {
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': $('meta[name="csrf-token"]').attr('content')
        }
      }
    ).then(response => response.json())
      .then(data => {
        this.titleTarget.dataset.pdfid = data.response.attributes.table.id
        this.getPdfData()
      })
  }

  getPdfData() {
    fetch(`/reports/${this.titleTarget.dataset.reportid}/pdf_status?document_id=${this.titleTarget.dataset.pdfid}`)
      .then(response => response.json())
      .then(data => {
        switch (data.status) {
          case 'success':
            this.downloadPdf(data.download_url)
            break
          default:
            this.displayError()
        }
      })
  }

  downloadPdf(download_url) {
    window.location.replace(download_url)
    this.spinnerTarget.classList.remove('spinner')
    this.titleTarget.innerText = `${window.i18n.t('report.report_downloaded')}`
  }

  displayError() {
    this.spinnerTarget.classList.remove('spinner')
    this.spinnerTarget.innerHTML = '<i class="fa-solid fa-times fa-2xl" style="color: #FF5C00;"></i>'
    this.titleTarget.innerText   = `${window.i18n.t('report.error_generating_report')}`
  }
}
