import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "eye"]

  toggleField() {
    if (this.inputTarget.attributes['type'].value === "password") {
      this.inputTarget.attributes['type'].value = 'text'
      this.eyeTarget.innerHTML = '<i class="fas fa-eye-slash">'
    } else {
      this.inputTarget.attributes['type'].value = 'password'
      this.eyeTarget.innerHTML = '<i class="fas fa-eye">'
    }
  }
}