import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["controllerDiv", "inputs", "buttons"]

  showChoice(event) {
    const choice = event.target.dataset.inputChoice
    this.inputsTarget.querySelectorAll(".exclusif_input").forEach((input) => {
      if (input.tagName === 'SELECT') {}
      else  {
        input.value = "";
      }
    })
    // write a function to loop through a div's children and add or remove a class


    Array.from(this.inputsTarget.children).forEach((div) => {
      if (div.id != choice) {
        div.classList.add("hidden")
      }
      else {
        div.classList.remove("hidden")
      }
    })
    Array.from(this.buttonsTarget.children).forEach((button) => {
      button.classList.remove("active")
    })
    event.target.classList.add("active")
  }

}