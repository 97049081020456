import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['defaultColumn']

  selectDefaultContact(e) {
    const contactId = e.currentTarget.closest('td').dataset.invoiceContactId
    const companyId = this.element.dataset.companyId

    fetch(`/companies/${companyId}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      method: 'PATCH',
      body:   JSON.stringify({ company: { default_invoice_contact_id: contactId } })
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors) { return alert(data.errors) }

        this.element.querySelectorAll('td.default-column').forEach((element) => {
          const elementId = element.dataset.invoiceContactId

          if (elementId === contactId) {
            element.innerHTML = '<i class="fas fa-check-square text-primary"></i>'
          } else {
            element.innerHTML = '<i class="far fa-square" data-action="click->invoice-contacts--default-contact#selectDefaultContact"></i>'
          }
        })
      })
  }

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

}
