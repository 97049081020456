import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'materialQuantityInput',
    'amountInput'
  ]

  connect() {
    this.updateQuantity()
  }

  updateQuantity() {
    fetch(`/material_quantities/${this.materialQuantityInputTarget.value}`, {
      method: 'GET',
      headers: { "Content-Type": "application/json", "X-CSRF-Token": this.csrfToken }
    })
    .then(response => response.json())
    .then(data => {
      this.amountInputTarget.value = data.quantity
    })
  }

}
