import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'main_div' ,
    'existingTableDisplayId',
    'newTableDisplayName'
  ]

  select_all(event) {
    var checkboxes = event.target.closest('.form-check-parent').querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach(checkbox => {
      checkbox.checked = true
    })
  }

  deselect_all(event) {
    var checkboxes = event.target.closest('.form-check-parent').querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach(checkbox => {
      checkbox.checked = false
    })
    document.getElementById('id').checked = true
  }

  apply(event, save=false) {
    var checkboxes = event.target.closest('.form-check-parent').querySelectorAll('input[type="checkbox"]')
    var selected_columns = []

    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        selected_columns.push(checkbox.value)
      }
    })

    var url       = '/table_displays/update_default_columns'
    var csrfToken = this.csrfToken
    var data      = {
      selected_columns: selected_columns.join(','),
      table_name:       this.tableName,
      save:             save
    }

    fetch(url, {
      method:  'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        window.location.replace(data.redirect)
      })
      .catch((_error) => {
        window.location.reload()
      })
  }

  get tableName() { return this.main_divTarget.getAttribute('data-table-name') }
  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }
}
