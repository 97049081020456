import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  get csrfToken() { return document.head.querySelector("meta[name='csrf-token']").getAttribute("content") }

  removeUser(event) {
    const linkId = event.currentTarget.dataset.link
    const conversationId = event.currentTarget.dataset.conversation
    // this.link = document.querySelector("#" + linkId)
    fetch(`/conversations/${conversationId}/remove_user?link=${linkId}`, 
      {
        method: 'DELETE',
        headers: {
          "Accept": "application/json",
          "X-CSRF-Token": this.csrfToken
        }
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        return response.blob();
      })
    .then( event.currentTarget.parentElement.remove())
    .catch((error) => {
      console.error('There has been a problem with your fetch operation:', error);
    });
  }
}