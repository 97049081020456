import Choices from 'choices.js'
import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['container', 'unitId', 'amenityId']

  connect() {
    const selector = this.element.querySelector('.js-choice-root-unit')
    new Choices(selector, {
      resetScrollPosition: false,
      removeItemButton:    true,
      itemSelectText:      '',
      allowHTML:           true,
      searchResultLimit:   10
    })
    selector.addEventListener('change', (e) => this.updateFilters(e))
    this.nextUnitLevel = 1

    if (document.getElementById('amenityFilter') && document.getElementById('amenitySelector')) {
      const amSelector = document.getElementById('amenitySelector')
      amSelector.addEventListener('change', (e) => this.updateAmenityFilters(e))
    }

    const event = new Event('change')
    selector.dispatchEvent(event)
  }

  get unitId() {
    return this.unitIdTarget.value
  }

  set unitId(newId) {
    this.unitIdTarget.value = newId
  }

  get amenityId() {
    return this.amenityIdTarget.value
  }

  set amenityId(newId) {
    this.amenityIdTarget.value = newId
  }

  get nextUnitLevel() {
    return this._nextUnitLevel
  }

  set nextUnitLevel(value) {
    this._nextUnitLevel = value
  }

  get reportTypeId() {
    if (document.getElementById('report_report_type_id')) {
      return document.getElementById('report_report_type_id').value
    } else {
      return ''
    }
  }

  updateFilters(e) {
    const selectedUnitId = e.target.value
    const currentUnitLevel = parseInt(e.target.closest('.unit-level').getAttribute('data-level'))

    this.deletePreviousSubUnitFilters(currentUnitLevel)
    this.nextUnitLevel = currentUnitLevel + 1
    this.unitId = selectedUnitId
    if (selectedUnitId !== '') {
      const choice = this.newChoiceInstance()
      this.populateFilterChoices(selectedUnitId, choice)
      this.nextUnitLevel += 1
    }

    if (selectedUnitId === '' && currentUnitLevel !== 0) {
      const parentUnitValue = document.getElementById(`level${currentUnitLevel - 1}`)
      this.unitId = parentUnitValue.value
    }
    if (document.getElementById('amenityFilter')) {
      this.populateAmenties()
    }

    // Manually trigger 'change' event for calendar re-render
    var event = new Event('change')
    this.unitIdTarget.dispatchEvent(event)
  }

  deletePreviousSubUnitFilters(currentUnitLevel) {
    document.querySelectorAll('.unit-level').forEach(element => {
      if (parseInt(element.getAttribute('data-level')) > currentUnitLevel) {
        element.remove()
      }
    })
  }

  newChoiceInstance() {
    let containerClass = 'js-choice-container'
    if (this.containerTarget.hasAttribute('data-large-containers')) {
      containerClass = 'js-choice-container-large'
    }
    const newSelect = `
      <div class="form-group select optional form-group-valid ${containerClass} unit-level" data-level=${this.nextUnitLevel}>
        <label class="form-control-label select optional">${window.i18n.t('unit.subunit')} ${this.nextUnitLevel}</label>
        <select id="level${this.nextUnitLevel}" data-level=${this.nextUnitLevel} class="form-control select optional">
          <option value="">${window.i18n.t('shared.none')}</option>
        </select>
      </div>
    `
    document.querySelector('.unit-filters-container').insertAdjacentHTML('beforeEnd', newSelect)

    const selector = document.getElementById(`level${this.nextUnitLevel}`)
    const choice = new Choices(selector, {
      resetScrollPosition: false,
      removeItemButton:    true,
      itemSelectText:      '',
      allowHTML:           true,
      searchResultLimit:   10
    })
    selector.addEventListener('change', (e) => this.updateFilters(e))

    return choice
  }

  async populateFilterChoices(selectedUnitId, choice) {
    const subUnits = []
    const data = await this.fetchSubUnits(selectedUnitId)

    data.forEach((unit) => {
      subUnits.push({
        value: unit.id,
        label: unit.name
      })
    })

    choice.setChoices(
      subUnits,
      'value',
      'label',
      false
    )
  }

  fetchSubUnits(selectedUnitId) {
    return fetch(`/units/${selectedUnitId}/children_units.json`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
      .then(response => response.json())
      .then(data => data)
  }

  async populateAmenties() {
    this.amenityId = ''
    document.getElementById('amenityFilter').innerHTML = ''
    const amenities = []
    const data = await this.fetchAmenities()
    data.forEach((amenity) => {
      amenities.push({
        value: amenity.id,
        label: amenity.name
      })
    })



    const choice = this.newAmenitiesInstance()
    choice.setChoices(
      amenities,
      'value',
      'label',
      false
    )
  }

  get amenityRequired() {
    return this.amenityIdTarget.dataset.mandatory === 'true'
  }

  newAmenitiesInstance() {
    const mandatory_label = this.amenityRequired ? '<abbr title="required">*</abbr>' : ''
    const mandatory_select = this.amenityRequired ? 'required' : ''
    const newSelect = `
      <label class="form-control-label select optional">${window.i18n.t('amenity.amenity')}</label>
      ${mandatory_label}
      <select class="form-control select optional" ${mandatory_select} id="amenitySelector">
        <option value="">${window.i18n.t('shared.none')}</option>
      </select>
    `

    document.getElementById('amenityFilter').innerHTML = ''
    document.getElementById('amenityFilter').insertAdjacentHTML('beforeEnd', newSelect)

    const selector = document.getElementById('amenitySelector')
    const choice = new Choices(selector, {
      resetScrollPosition: false,
      removeItemButton:    true,
      itemSelectText:      '',
      allowHTML:           true,
      searchResultLimit:   10
    })
    selector.addEventListener('change', (e) => this.updateAmenityFilters(e))

    return choice
  }


  fetchAmenities() {
    let archived = ''
    if (this.amenityIdTarget.dataset.archived) {
      archived = `&archived=${this.amenityIdTarget.dataset.archived}`
    }

    return fetch(`/amenities.json?unit=${this.unitId}&report_type=${this.reportTypeId}&paginated=false&content=short&subtree=false${archived}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
      .then(response => response.json())
      .then(data => data)
  }

  updateAmenityFilters(e) {
    const selectedAmenityId = e.target.value
    this.amenityId          = selectedAmenityId
    const event             = new Event('change')
    this.amenityIdTarget.dispatchEvent(event)
  }

}
