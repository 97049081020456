import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = [
    'dates',
    'type',
    'addBtn'
  ]

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  add(event) {
    var user_id = event.target.dataset.user
    var dates = this.datesTarget.value
    var type_id = this.typeTarget.value
    var addBtn = this.addBtnTarget
    var closeBtn = addBtn.closest('.modal').querySelector('.btn-close')
    fetch(`/offdays.json?user_id=${user_id}&dates=${dates}&dayoff_type_id=${type_id}`, {
      method:  'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
      .then(response => response.json())
      .then(() => {
        if (closeBtn) {
          closeBtn.click()
        }
        if (window.calendar) {
          window.calendar.refetchEvents()
        }
      })
  }
}