import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  

  connect() {
    document.getElementById('drop-task').querySelectorAll('button.btn-close').forEach((elem) => {
      elem.onclick = function() {
        window.calendar.refetchEvents()
        window.calendar.render()
        // location.reload()
      }
    })
  }

  cancel(event) {
    document.getElementById('drop-task').querySelectorAll('button.btn-close').forEach((elem) => {
      elem.click()
    })
    window.calendar.getEventById(event.target.dataset.eventid).remove()
  }

  submit() {
    if (document.querySelector('.mandatory_expected_date')) {
      if (document.querySelector('.mandatory_expected_date').value != '') {
        document.getElementById('drop-task').querySelectorAll('button.btn-close').forEach((elem) => {
          elem.click()
        })
      }
    }
    else {
      document.getElementById('drop-task').querySelectorAll('button.btn-close').forEach((elem) => {
        elem.click()
      })
    }
  }
} 