import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['assetList', 'table']

  addUnits() {
    var list = Array.from(this.tableTarget.querySelectorAll('.unit_list_item')).map((element) => element.dataset.unit)
    this.assetListTarget.value = list
  }

  addAmenities() {
    var list = Array.from(this.tableTarget.querySelectorAll('.amenity_list_item')).map((element) => element.dataset.amenity)
    this.assetListTarget.value = list
  }

}
