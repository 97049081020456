import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'selectAllBtn',
    'checkBoxes'
  ]

  toggleSelectAll() {
    if (this.selectAllBtnTarget.checked) {
      this.inputs.forEach((input) => input.checked = true)
    } else {
      this.inputs.forEach((input) => input.checked = false)
    }
  }

  get inputs() {
    return this.checkBoxesTarget.querySelectorAll('input[type="checkbox"]')
  }

}
