import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dateInputs", "startDate", "endDate"]

  toggleDateInputs(e) {
    if (e.target.value === '') {
      this.dateInputsTarget.classList.add('hidden')
      this.startDateTarget.required = false
      this.endDateTarget.required   = false
    } else {
      this.dateInputsTarget.classList.remove('hidden')
      this.startDateTarget.required = true
      this.endDateTarget.required   = true
    }
  }

  setEndDateMin() {
    this.endDateTarget.setAttribute('min', this.startDateTarget.value)
  }

}
