import { Controller } from '@hotwired/stimulus'
import XLSX from 'xlsx'

export default class extends Controller {
  static targets = ['file_input', 'importBtn', 'table', 'tableHeader', 'tableData', 'progress', 'progressWrapper', 'company', 'importNumber', 'deleteBtn', 'job']

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  get jobName()   { return this.jobTarget.getAttribute('data-job') }

  get jobFolder() { return this.jobTarget.getAttribute('data-folder') }

  connect() {
    console.log('import_controller connected')
    console.log('jobName', this.jobName)
  }

  async prepare_import(event) {
    this.tableDataTarget.innerHTML   = ''
    this.tableHeaderTarget.innerHTML = ''
    await this.read_file(event)
  }

  async read_file(event) {
    const file     = event.target.files[0]
    const data     = await file.arrayBuffer()
    const workbook = XLSX.read(data)
    const sheet    = workbook.Sheets[workbook.SheetNames[0]]
    this.json      = XLSX.utils.sheet_to_json(sheet)

    console.log('json', this.json)
  }

  import() {
    const data = this.json
    console.log('data :', data)
    fetch('/admin_import.json', {
      method:  'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        job_folder: this.jobFolder,
        import_job: this.jobName,
        data:       data,
        company_id: this.companyTarget.value,
      })
    })
      .then(response => response.json())
      .then(data => {
        const jobId = data.job.job_id
        const jobProviderId = data.job.job_provider_id
        this.progressTarget.classList.remove('hidden')
        this.progressTarget.textContent = `${window.i18n.t('shared.processing')}`
        this.timer = setInterval(() => {
          this.checkJobStatus(jobId, jobProviderId)
        }, 1000)
      })
  }

  checkJobStatus(jobId, jobProviderId) {
    fetch(`/import_status?job_id=${jobId}&job_provider_id=${jobProviderId}`)
      .then(response => response.json())
      .then(data => {
        const percentage = data.percentage
        const importNumberValue = data.import_number
        this.progressTarget.textContent = `${window.i18n.t('shared.processing')} ${percentage}%`
        if (data.status == 'error') {
          this.stopCheckJobStatus()
        } else if (data.status === 'complete') {
          this.stopCheckJobStatus()
          this.progressTarget.textContent = `${window.i18n.t('shared.import_number')} ${importNumberValue} - ${window.i18n.t('shared.processing_completed')}`
          this.deleteBtnTarget.classList.remove('hidden')
          this.deleteBtnTarget.href = `/imports/${importNumberValue}`
        }
      })
  }

  stopCheckJobStatus() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  disconnect() {
    this.stopCheckJobStatus()
  }

}
