import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../src/config/i18n.js'
export default class extends Controller {
  static targets = ['form', 'startDate', 'endDate', 'download', 'progress', 'progressWrapper']

  export() {
    var start = this.startDateTarget.value
    var end   = this.endDateTarget.value

    if (start === '' || end === '') { return alert(i18n.t('material.error.select_start_and_end')) }

    var materialId = this.formTarget.dataset.material
    fetch(`/materials/export_usages?start=${start}&end=${end}&material_id=${materialId}`)
      .then(response => response.json())
      .then(data => {
        const jobId = data.job.job_id
        const jobProviderId = data.job.job_provider_id
        this.formTarget.classList.add('hidden')
        this.progressTarget.classList.remove('hidden')
        this.progressTarget.textContent = `${i18n.t('shared.processing')}`
        this.downloadTarget.classList.add('hidden')
        this.timer = setInterval(() => {
          this.checkJobStatus(jobId, jobProviderId)
        }, 1000)
      })
  }

  checkJobStatus(jobId, jobProviderId) {
    fetch(`/export_status?job_id=${jobId}&job_provider_id=${jobProviderId}`)
      .then(response => response.json())
      .then(data => {
        const percentage = data.percentage
        this.progressTarget.textContent = `${i18n.t('shared.processing')} ${percentage}%`
        if(data.status == 'error') {
          this.stopCheckJobStatus()
        } else if(data.status === 'complete') {
          this.stopCheckJobStatus()
          this.progressTarget.textContent = `${i18n.t('shared.processing_completed')}`
          this.progressTarget.insertAdjacentHTML('afterend', `${i18n.t('shared.file_sent_to_your_email_address')}`)
        }
      })
  }

  stopCheckJobStatus() {
    if(this.timer) {
      clearInterval(this.timer)
    }
  }

  disconnect() {
    this.stopCheckJobStatus()
  }
}
