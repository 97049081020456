import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['filters', 'button', 'icon']

  connect() {
    this.buttonTarget.addEventListener('click', () => this.toggleFilters())
  }

  toggleFilters() {
    this.filtersTarget.classList.toggle('hidden')
  }
}
