import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../src/config/i18n.js'
export default class extends Controller {
  static targets = ['id', 'responsibleBtn', 'deadlineBtn', 'defaultCostBtn']

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  get companyId() {
    return this.idTarget.dataset.id
  }

  toggle(event) {
    let button
    if (event.target.tagName == 'I') {
      button = event.target.parentElement
    } else {
      button = event.target
    }
    const parameter = button.dataset.parameter
    const value = button.dataset.value
    document.querySelectorAll(`[data-parameter="${parameter}"]`).forEach((element) => {
      if (element.dataset.value == value) {
        element.classList.add('active')
      } else {
        element.classList.remove('active')
      }
    })

    fetch(`/companies/${this.companyId}`, {
      method:  'PATCH',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.csrfToken },
      body:    JSON.stringify({ company: { [parameter]: value } })
    })

  }

  toggle_roles(event) {
    const role = event.target.dataset.role
    const selected_value = event.target.dataset.value
    const field = event.target.dataset.field
    if (role && selected_value && field) {
      fetch(`/companies/${this.companyId}/update_role_permissions`, {
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        body: JSON.stringify({
          role:  role,
          value: selected_value == 'true' ? true : false,
          field: field
        }),
        method: 'PATCH',
      }).then(response => response.json())
        .then(_data => {
          event.target.closest('.parameter-select')
            .querySelectorAll(`button[data-role="${role}"]`)
            .forEach(element => {
              if (element.dataset.value == selected_value) {
                element.classList.add('active')
              } else {
                element.classList.remove('active')
              }
            })
        })
    }
  }


  responsible_select() {
    let params = []
    document.querySelectorAll('.parameter-select').forEach((element) => {
      params.push([`${element.id}_id`, element.value])

    })
    // transform an array of arrays into an object that can be used in the fetch body

    const parameters = Object.fromEntries(params)
    fetch(`/companies/${this.companyId}`, {
      method:  'PATCH',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.csrfToken },
      body:    JSON.stringify({ company: parameters })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.message == 'ok') {
          this.btn_to_saved(this.responsibleBtnTarget)
        }
      })
  }


  set_default_deadline() {
    const deadline = document.getElementById('default_deadline_days').value * 86400 + document.getElementById('default_deadline_hours').value * 3600
    fetch(`/companies/${this.companyId}`, {
      method:  'PATCH',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.csrfToken },
      body:    JSON.stringify({ company: { default_deadline: deadline } })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.message == 'ok') {
          this.btn_to_saved(this.deadlineBtnTarget)
        }
      })
  }

  set_default_costs() {
    let params = []
    document.querySelectorAll('.parameter-costs').forEach((element) => {
      params.push([`${element.id}`, element.value * 100])

    })
    // transform an array of arrays into an object that can be used in the fetch body

    const parameters = Object.fromEntries(params)
    fetch(`/companies/${this.companyId}`, {
      method:  'PATCH',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.csrfToken },
      body:    JSON.stringify({ company: parameters })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.message == 'ok') {
          this.btn_to_saved(this.defaultCostBtnTarget)
        }
      })
  }

  btn_to_save(event) {
    const btnId   = event.params.btnid
    const action  = event.params.action
    const btn     = document.getElementById(btnId)
    btn.innerHTML = i18n.t('actions.save')
    btn.classList.remove('btn-success')
    btn.classList.add('btn-outline-primary')
    btn.dataset.action = `click->companies--parameters#${action}`
  }

  btn_to_saved(btn) {
    btn.innerHTML = i18n.t('shared.changed_saved')
    btn.classList.add('btn-success')
    btn.classList.remove('btn-outline-primary')
    btn.dataset.action = ''
  }
}
