import Choices from 'choices.js'
import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'

export default class extends Controller {

  static targets = [
    'main_div',
    'filter_selector_div',
    'active_filters_div',
  ]

  select_filter(event) {
    var filter_id   = event.target.value
    var filter_name = event.target.options[event.target.selectedIndex].text
    var filter_type = event.target.options[event.target.selectedIndex].getAttribute('data-filter')

    switch(filter_type) {
      case 'free_text':
        this.add_text_filter(filter_id, filter_name, filter_type)
        break
      case 'date':
        this.add_date_filter(filter_id, filter_name, filter_type)
        break
      case 'date_time':
        this.add_date_time_filter(filter_id, filter_name, filter_type)
        break
      case 'multiple_choice':
        this.add_multiple_choice_filter(filter_id, filter_name, filter_type)
        break
      case 'true_false':
        this.add_boolean_filter(filter_id, filter_name, filter_type)
        break
      default:
        this.add_text_filter(filter_id, filter_name, filter_type)
        break
    }
    event.target.options[event.target.selectedIndex].remove()
    event.target.selectedIndex = 0

    this.saveFilter(filter_id, filter_type)
  }

  saveFilter(filter_column, filter_type) {
    const value  = filter_type === 'multiple_choice' ? [] : ''
    const data = {
      filter_column: filter_column,
      filter_value:  value,
      filter_type:   filter_type,
      table_name:    this.tableName
    }
    fetch('/table_displays/update_default_filters', {
      method:  'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify(data)
    }).then(response => response.json())
  }

  add_boolean_filter(filter_id, filter_name, filter_type) {
    const newBooleanFilter = `
      <div class="form-group select optional form-group-valid js-choice-container" data-filter-id="${filter_id}" data-filter-type="${filter_type}">
        <label class="form-control-label select optional">
          ${filter_name}<i class="fas fa-trash pointer text-danger ps-2" data-action="click->table-display--filters-selector#remove_filter"></i>
        </label>
        <select class="form-control select optional" id="${filter_id}Selector">
        </select>
      </div>
    `
    this.active_filters_divTarget.insertAdjacentHTML('beforeend', newBooleanFilter)

    const options = [
      { value: '',      label: window.i18n.t('shared.all')  },
      { value: 'true',  label: window.i18n.t('shared._yes') },
      { value: 'false', label: window.i18n.t('shared._no')  }
    ]

    const selector = document.getElementById(`${filter_id}Selector`)
    const choice   = new Choices(selector, {
      resetScrollPosition: false,
      removeItemButton:    true,
      itemSelectText:      '',
      allowHTML:           true,
      searchResultLimit:   10
    })

    choice.setChoices(
      options,
      'value',
      'label',
      false
    )

    selector.setAttribute('data-action', 'change->table-display--filters-selector#apply_filter')
  }

  add_text_filter(filter_id, filter_name, filter_type) {
    const newInput = `
      <div class="form-group select optional form-group-valid js-choice-container" data-filter-id="${filter_id}" data-filter-type="${filter_type}">
        <label class="form-control-label select optional">
          ${filter_name}<i class="fas fa-trash pointer text-danger ps-2" data-action="click->table-display--filters-selector#remove_filter"></i>
        </label>
        <input type="text" class="js-choice-text form-control" data-action="change->table-display--filters-selector#apply_filter" id="${filter_id}Input">
      </div>
    `
    this.active_filters_divTarget.insertAdjacentHTML('beforeend', newInput)

    const input = document.getElementById(`${filter_id}Input`)
    new Choices(input, {
      maxItemCount:          1,
      duplicateItemsAllowed: false,
      allowHTML:             true,
      addItemText:           'Press Enter to add'
    })
  }

  async add_multiple_choice_filter(filter_id, filter_name, filter_type) {
    const response = await fetch(`/table_displays/column_select_options?table_name=${this.tableName}&column_name=${filter_id}`, {
      method:  'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })

    const newSelectFilter = `
      <div class="form-group select optional form-group-valid js-choice-container" data-filter-id="${filter_id}" data-filter-type="${filter_type}">
        <label class="form-control-label select optional">
          ${filter_name}<i class="fas fa-trash pointer text-danger ps-2" data-action="click->table-display--filters-selector#remove_filter"></i>
        </label>
        <select multiple class="form-control select optional" id="${filter_id}Selector" >
          <option value="">${window.i18n.t('shared.all')}</option>
        </select>
      </div>
    `
    this.active_filters_divTarget.insertAdjacentHTML('beforeend', newSelectFilter)

    const options = await response.json()
    const selectChoices = []
    options.forEach((option) => {
      selectChoices.push({
        value: option.value,
        label: option.label
      })
    })

    const selector = document.getElementById(`${filter_id}Selector`)
    const choice   = new Choices(selector, {
      resetScrollPosition: false,
      removeItemButton:    true,
      itemSelectText:      '',
      allowHTML:           true,
      searchResultLimit:   10
    })

    choice.setChoices(
      selectChoices,
      'value',
      'label',
      false
    )

    selector.setAttribute('data-action', 'change->table-display--filters-selector#apply_filter')
  }

  add_date_filter(filter_id, filter_name, filter_type) {
    this.active_filters_divTarget.insertAdjacentHTML('beforeend', `
        <div  class="form-group select optional form-group-valid js-choice-container"
              data-controller="launch-mobiscroll"
              data-filter-id="${filter_id}"
              data-filter-type="${filter_type}">
          <label class="form-control-label select optional">
            ${filter_name}<i class="fas fa-trash pointer text-danger ps-2" data-action="click->table-display--filters-selector#remove_filter"></i>
          </label>
          <input  data-launch-mobiscroll-target="input"
                  data-action="change->table-display--filters-selector#apply_filter" id="${filter_id}"
                  class="filter_selector launch-mobiscroll choices__inner"
                  data-script="mobiscrollDateRangeClick"
                  data-skip-clear='true'
                  mbsc-input="">
        </div>
      `
    )
  }

  add_date_time_filter(filter_id, filter_name, filter_type) {
    this.active_filters_divTarget.insertAdjacentHTML('beforeend', `
        <div  class="form-group select optional form-group-valid js-choice-container"
              data-controller="launch-mobiscroll"
              data-filter-id="${filter_id}"
              data-filter-type="${filter_type}">
          <label class="form-control-label select optional">
            ${filter_name}<i class="fas fa-trash pointer text-danger ps-2" data-action="click->table-display--filters-selector#remove_filter"></i>
          </label>
          <input  data-launch-mobiscroll-target="input"
                  data-action="change->table-display--filters-selector#apply_filter" id="${filter_id}"
                  class="filter_selector launch-mobiscroll choices__inner"
                  data-script="mobiscrollTimeRangeClick"
                  data-default-start="${dayjs().startOf('day')}"
                  data-default-stop="${dayjs().endOf('day')}"
                  data-skip-clear='true'
                  mbsc-input="">
        </div>
      `
    )
  }

  async apply_filter(event){
    const url           = '/table_displays/update_default_filters'
    const filter_column = event.target.closest('[data-filter-id]').dataset.filterId
    const filter_type   = event.target.closest('[data-filter-type]').dataset.filterType
    const filter_value  = filter_type === 'multiple_choice' ? Array.from(event.target.selectedOptions).map(option => option.value) : event.target.value
    const data = {
      filter_column: filter_column,
      filter_value:  filter_value,
      filter_type:   filter_type,
      table_name:    this.tableName
    }
    fetch(url, {
      method:  'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(_data => {
        fetch(this.element.dataset.fetch, {
          headers: { Accept: 'text/vnd.turbo-stream.html' }
        })
          .then(r => r.text())
          .then(html => Turbo.renderStreamMessage(html))
      })
      .catch((_error) => {
        window.location.reload()
      })
  }

  remove_filter(event) {
    event.target.closest('div').remove()
    var url           = '/table_displays/remove_default_filter'
    var filter_column = event.target.closest('div').dataset.filterId
    var data          = {
      filter_column: filter_column,
      table_name:    this.tableName
    }
    fetch(url, {
      method:  'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(_data => {
        fetch(this.element.dataset.fetch, {
          headers: { Accept: 'text/vnd.turbo-stream.html' }
        })
          .then(r => r.text())
          .then(html => Turbo.renderStreamMessage(html))
      })
      .catch((_error) => {
        window.location.reload()
      })
  }

  get tableName() { return this.main_divTarget.getAttribute('data-table-name') }
  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }
}
