import Choices from 'choices.js'
import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['container', 'input']

  connect() {
    const selector = this.element.querySelector('.js-choice-root-ancestry')
    new Choices(selector, {
      resetScrollPosition: false,
      removeItemButton:    true,
      itemSelectText:      '',
      allowHTML:           true,
      searchResultLimit:   10
    })
    selector.addEventListener('change', (e) => this.updateFilters(e))
    this.nextLevel = 1
  }

  updateFilters(e) {
    const selectedinput = e.target.value
    const currentLevel  = parseInt(e.target.closest('.ancestry-level').getAttribute('data-level'))

    this.deletePreviousSubLevel(currentLevel)
    this.nextLevel = currentLevel + 1
    this.input     = selectedinput

    if (selectedinput !== '') {
      const choice = this.newChoiceInstance()
      this.populateFilterChoices(selectedinput, choice)
      this.nextLevel += 1
    }

    if (selectedinput === '' && currentLevel !== 0) {
      const parentLevel = document.getElementById(`level${currentLevel - 1}`)
      this.input = parentLevel.value
    }

    // Manually trigger 'change' event for calendar re-render
    var event = new Event('change')
    this.inputTarget.dispatchEvent(event)
  }

  deletePreviousSubLevel(currentLevel) {
    this.element.querySelectorAll('.ancestry-level').forEach(element => {
      if (parseInt(element.getAttribute('data-level')) > currentLevel) {
        element.remove()
      }
    })
  }

  newChoiceInstance() {
    const container = this.containerTarget.dataset.containerSize === 'small' ? 'js-choice-container' : 'js-choice-container-large'
    const newSelect = `
      <div class="form-group select optional form-group-valid ${container} ancestry-level" data-level=${this.nextLevel}>
        <label class="form-control-label select optional">${window.i18n.t('material_location.sublocation')} ${this.nextLevel}</label>
        <select id="level${this.nextLevel}" data-level=${this.nextLevel} class="form-control select optional">
          <option value="">${window.i18n.t('shared.all')}</option>
        </select>
      </div>
    `
    this.element.insertAdjacentHTML('beforeEnd', newSelect)

    const selector = this.element.querySelector(`#level${this.nextLevel}`)
    const choice = new Choices(selector, {
      resetScrollPosition: false,
      removeItemButton:    true,
      itemSelectText:      '',
      allowHTML:           true,
      searchResultLimit:   10
    })
    selector.addEventListener('change', (e) => this.updateFilters(e))

    return choice
  }

  async populateFilterChoices(selectedinput, choice) {
    const subLevels = []
    const data = await this.fetchSubLevels(selectedinput)
    data.forEach((child) => {
      subLevels.push({
        value: child.id,
        label: child.full_name
      })
    })

    choice.setChoices(
      subLevels,
      'value',
      'label',
      false
    )
  }

  fetchSubLevels(selectedinput) {
    return fetch(`/material_locations/${selectedinput}/children.json`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
      .then(response => response.json())
      .then(data => data)
  }


  get input()     { return this.inputTarget.value }
  get nextLevel() { return this._nextLevel }

  set input(newId)     { this.inputTarget.value = newId }
  set nextLevel(value) { this._nextLevel = value }

}
