import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cart', 'requestId']

  add(event) {
    const mat_id = event.target.getAttribute('data-mat-id')
    const value  = event.target.getAttribute('data-quantity')
    fetch(`/material_requests/${this.material_request_id}/add_material`,
      {
        method:  'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        },
        body: JSON.stringify({ material_id: mat_id, quantity: value })
      })
      .then(response => response.json())
      .then(data => {
        var existingLine = document.getElementById(`material_request_line_${data.response.id}`)
        var tableInput   = document.getElementById(`material_quantity_input_${data.response.material.id}`)
        if (tableInput) {
          tableInput.value = data.response.quantity_requested <= 0 ? '' : data.response.quantity_requested
        }
        if (existingLine && data.response.quantity_requested <= 0) {
          return existingLine.remove()
        }
        this.updateMaterial(data, existingLine)
      })
  }

  add_value(event) {
    const mat_id = event.target.getAttribute('data-mat-id')
    const value  = event.target.value
    fetch(`/material_requests/${this.material_request_id}/update_material`,
      {
        method:  'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        },
        body: JSON.stringify({ material_id: mat_id, quantity: value })
      })
      .then(response => response.json())
      .then(data => {
        var existingLine = document.getElementById(`material_request_line_${data.response.id}`)
        var tableInput   = document.getElementById(`material_quantity_input_${data.response.material.id}`)
        if (tableInput) {
          tableInput.value = data.response.quantity_requested <= 0 ? '' : data.response.quantity_requested
        }
        if (existingLine && data.response.quantity_requested <= 0) {
          return existingLine.remove()
        }
        this.updateMaterial(data, existingLine)
      })
  }

  editMaterial(e) {
    const id          = e.target.dataset.lineId
    const quantity    = document.getElementById('material_request_line_quantity_requested').value
    const name        = document.getElementById('material_request_line_material_name')?.value
    const brand       = document.getElementById('material_request_line_material_brand')?.value
    const description = document.getElementById('material_request_line_description')?.value

    fetch(`/material_request_lines/${id}`, {
      method:  'PATCH',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        material_request_line: {
          quantity_requested: quantity,
          material_name:      name,
          material_brand:     brand,
          description:        description
        }
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        var existing_line = document.getElementById(`material_request_line_${data.response.id}`)
        var tableInput    = document.getElementById(`material_quantity_input_${data.response.material?.id}`)
        if (tableInput) {
          tableInput.value = data.response.quantity_requested <= 0 ? '' : data.response.quantity_requested
        }
        if (existing_line && data.response.quantity_requested <= 0) {
          existing_line.remove()
          document.getElementById('edit-line')
            .querySelector('.btn-close')
            .click()
          return
        }
        this.updateMaterial(data, existing_line)
        document.getElementById('edit-line')
          .querySelector('.btn-close')
          .click()
      })
  }

  updateMaterial(data, existingLine) {
    if (existingLine) {
      existingLine.querySelector('.mat_order_quantity').innerText = `${window.i18n.t('material.quantity')}: ${data.response.quantity_requested}`
      existingLine.querySelector('.mat_brand').innerText          = `${window.i18n.t('material.brand')}: ${data.response.brand}`
      existingLine.querySelector('.mat_name').innerText           = `${data.response.name}`
      return
    }

    this.cart_list.insertAdjacentHTML('afterbegin', `
        <li class="list-group-item list-group-item-action" id="material_request_line_${data.response.id}">
          <div class="card_content">
            <div class="card_title mat_name">
              ${data.response.name}
            </div>
            <div class="card_address simple_text mat_brand">
              ${window.i18n.t('material.brand')}: ${data.response.brand}
            </div>
            <div class="card_address simple_text mat_order_quantity">
              ${window.i18n.t('material.quantity')}: ${data.response.quantity_requested}
            </div>
          </div>
          <div class="btn-container align-items-center">
            <a class="hover-pointer" data-remote="true" href="/openModal?div=edit-line-content&amp;material_request_line=${data.response.id}&amp;modal=material_requests%2Fedit_line&amp;modal_id=edit-line-btn">
              <i class="fas fa-pen"></i>
            </a>
            <i class="fas fa-trash-alt pointer text-primary ms-1 text-danger"
               data-mat-id="${data.response.material?.id}"
               data-line-id="${data.response.id}"
               data-action="click->material-request#remove"></i>
          </div>
        </li>
      `
    )
  }

  remove(event) {
    let lineId = event.target.getAttribute('data-line-id')

    if (lineId === null) {
      const matId = event.target.getAttribute('data-mat-id')
      const item  = this.cartTarget.querySelector(`.list-group-item [data-mat-id="${matId}"]`)
      lineId      = item.getAttribute('data-line-id')
    }

    fetch(`/material_request_lines/${lineId}`,
      {
        method:  'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        var tableInput = document.getElementById(`material_quantity_input_${data.response.material?.id}`)
        if (tableInput) { tableInput.value = '' }
        document.getElementById(`material_request_line_${data.response.id}`).remove()
      })
  }

  reopen(event) {
    const request_line_id = event.target.dataset.lineId
    fetch(`/material_request_lines/${request_line_id}/reopen`,
      {
        method:  'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data.response.material_quantity === null) {
          document.getElementById(`line-quantity-${request_line_id}`).innerText     = ''
          document.getElementById(`material-quantity-${request_line_id}`).innerText = `${Number(data.response.material.quantity).toFixed(1)}`
          document.getElementById(`button-div-${request_line_id}`).innerHTML        = ''
          document.getElementById(`button-div-${request_line_id}`).innerHTML        = `
            <a data-remote="true" href="/openModal?div=select-location-content&amp;material_request_line=${request_line_id}&amp;modal=material_requests%2Fselect_location&amp;modal_id=select-location-btn">
              <div class="btn btn-outline-primary" style="margin-right: 0.5rem; margin-left: 0.5rem;">
                ${window.i18n.t('material_request.actions.select_location')}
              </div>
            </a>
          `
          if (document.getElementById('ready-request-btn')) {
            document.getElementById('ready-request-btn').classList.add('hidden')
          }
        }
      })
  }

  resetUsage(event) {
    const request_line_id = event.target.dataset.lineId
    fetch(`/material_request_lines/${request_line_id}/reset_usage`,
      {
        method:  'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        document.getElementById(`line-quantity-used-${request_line_id}`).innerText = `${Number(data.response.offer_line.quantity).toFixed(1)}`
        document.getElementById(`material-quantity-${request_line_id}`).innerText  = `${Number(data.response.material.quantity).toFixed(1)}`
        document.getElementById(`button-div-${request_line_id}`).innerHTML         = ''
        document.getElementById(`button-div-${request_line_id}`).innerHTML         = `
          <a data-remote="true" href="/openModal?div=close-line-content&amp;material_request_line=${request_line_id}&amp;modal=material_requests%2Fclose_line&amp;modal_id=close-line-btn">
            <div class="btn btn-outline-primary" style="margin-right: 0.5rem; margin-left: 0.5rem;">
              ${window.i18n.t('actions.validate')}
            </div>
          </a>
        `
        if (document.getElementById('add-to-report-btn')) {
          document.getElementById('add-to-report-btn').classList.add('hidden')
        }
      })
  }

  get material_request_id() {
    return this.requestIdTarget.getAttribute('data-request-id')
  }

  get cart_list() {
    return this.cartTarget
  }

  get inputs() {
    return this.inputsTarget
  }

  get csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

}
