import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cart', 'quotationId']


  add(event) {
    const mat_id = event.target.getAttribute('data-mat-id')
    const value  = event.target.getAttribute('data-quantity')
    fetch(`/material_quotations/${this.material_quotation_id}/add_quantity`,
      {
        method:  'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        },
        body: JSON.stringify({ mat_id: mat_id, value: value })
      })
      .then(response => response.json())
      .then(data => {
        let existing_line = document.getElementById(`material_quotation_line_${data.response.id}`)
        var tableInput    = document.getElementById(`material_quantity_input_${data.response.material.id}`)
        if (tableInput) {
          tableInput.value = data.response.quantity <= 0 ? '' : data.response.quantity
        }
        if (existing_line && data.response.quantity <= 0) {
          return existing_line.remove()
        }
        this.updateMaterial(data, existing_line)
      })
  }

  add_value(event) {
    const mat_id = event.target.getAttribute('data-mat-id')
    const value  = event.target.value
    fetch(`/material_quotations/${this.material_quotation_id}/update_quantity`,
      {
        method:  'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        },
        body: JSON.stringify({ mat_id: mat_id, value: value })
      })
      .then(response => response.json())
      .then(data => {
        let existing_line = document.getElementById(`material_quotation_line_${data.response.id}`)
        var tableInput    = document.getElementById(`material_quantity_input_${data.response.material.id}`)
        if (tableInput) {
          tableInput.value = data.response.quantity <= 0 ? '' : data.response.quantity
        }
        if (existing_line && data.response.quantity <= 0) {
          return existing_line.remove()
        }
        this.updateMaterial(data, existing_line)
      })
  }

  editMaterial(e) {
    const lineId   = e.target.dataset.lineId
    const quantity = document.getElementById('material_quotation_line_quantity').value

    fetch(`/material_quotation_lines/${lineId}`, {
      method:  'PATCH',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ material_quotation_line: { quantity: quantity } })
    })
      .then(response => response.json())
      .then(data => {
        var existing_line = document.getElementById(`material_quotation_line_${data.response.id}`)
        var tableInput    = document.getElementById(`material_quantity_input_${data.response.material.id}`)
        if (tableInput) {
          tableInput.value = data.response.quantity <= 0 ? '' : data.response.quantity
        }
        if (existing_line && data.response.quantity <= 0) {
          existing_line.remove()
          document.getElementById('edit-line')
            .querySelector('.btn-close')
            .click()
          return
        }
        this.updateMaterial(data, existing_line)
        document.getElementById('edit-line')
          .querySelector('.btn-close')
          .click()
      })
  }

  updateMaterial(data, existing_line) {
    if (existing_line) {
      return existing_line.querySelector('.mat_quantity').innerText = `${window.i18n.t('material.quantity')}: ${data.response.quantity}`
    }

    this.cart_list.insertAdjacentHTML('afterbegin', `
        <li class="list-group-item list-group-item-action" id="material_quotation_line_${data.response.id}">
          <div class="card_content">
            <div class="card_title">
              ${data.response.material.name}
              <a target="_blank" href="/materials/${data.response.material.id}">
                <i class="fa-solid fa-arrow-up-right-from-square fa-xs ms-1"></i>
              </a>
            </div>
            <div class="card_address simple_text">
              ${window.i18n.t('material.brand')}: ${data.response.material.brand}
            </div>
            <div class="card_address simple_text mat_quantity">
              ${window.i18n.t('material.quantity')}: ${data.response.quantity}
            </div>
          </div>
          <div class="btn-container align-items-center">
            <a class="hover-pointer" data-remote="true" href="/openModal?div=edit-line-content&amp;material_quotation_line=${data.response.id}&amp;modal=material_quotations%2Fpartials%2Fedit_line&amp;modal_id=edit-line-btn">
              <i class="fas fa-pen"></i>
            </a>
            <i class="fas fa-trash-alt pointer text-primary ms-1 text-danger"
               data-mat-id="${data.response.material.id}"
               data-line-id="${data.response.id}"
               data-action="click->material-quotations--prepare-lines#remove"></i>
          </div>
        </li>
      `
    )
  }

  remove(event) {
    let lineId = event.target.getAttribute('data-line-id')

    if (lineId === null) {
      const matId = event.target.getAttribute('data-mat-id')
      const item  = this.cartTarget.querySelector(`.list-group-item [data-mat-id="${matId}"]`)
      lineId      = item.getAttribute('data-line-id')
    }

    fetch(`/material_quotation_lines/${lineId}`,
      {
        method:  'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        var tableInput = document.getElementById(`material_quantity_input_${data.response.material.id}`)
        if (tableInput) { tableInput.value = '' }
        document.getElementById(`material_quotation_line_${data.response.id}`).remove()
      })
  }

  get material_quotation_id() {
    return this.quotationIdTarget.getAttribute('data-quotation-id')
  }

  get cart_list() {
    return this.cartTarget
  }

  get csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

}
