import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = ['field']
  static values  = { index: Number, field: String }

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  toggle(event) {

    const role           = event.target.dataset.role
    const selected_value = event.target.dataset.value

    if (this.indexValue) {
      fetch(`/report_types/${this.indexValue}/update_permissions`, {
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        body: JSON.stringify({
          role:  role,
          value: selected_value == 'true' ? true : false,
          field: this.fieldValue
        }),
        method: 'PATCH',
      }).then(response => response.json())
        .then(_data => {
          this.fieldTarget
            .querySelectorAll(`button[data-role="${role}"]`)
            .forEach(element => {
              if (element.dataset.value == selected_value) {
                element.classList.add('active')
              } else {
                element.classList.remove('active')
              }
            })
        })
    } else {
      this.fieldTarget
        .querySelectorAll(`button[data-role="${role}"]`)
        .forEach(element => {
          if (element.dataset.value == selected_value) {
            element.classList.add('active')
            document.getElementById(`prefiltering-${role}`).value = false
          } else {
            element.classList.remove('active')
            document.getElementById(`prefiltering-${role}`).value = true
          }
        })
    }

  }
}
