import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["card"]

  get csrfToken() { return document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content") }

  get materialId() {
    return this.cardTarget.dataset.material
  }

  get amenityId() {
    return this.cardTarget.dataset.amenity
  }

  

  remove() {
    fetch(`/amenities/${this.amenityId}/remove_material?material_id=${this.materialId}`, {
      method: 'PATCH',
      headers: { "Content-Type": "application/json", "X-CSRF-Token": this.csrfToken }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status == 'success') {
        this.cardTarget.remove()
      }
        
      else
        alert('Error')
      
    })

  }
}