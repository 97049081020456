import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'choices',
    'newchoice',
    'button',
    'inputfield',
    'replytype',
    'newchoices',
    'existingchoices',
    'extrachoices',
    'multiplechoice'
  ]

  get inputField() { return this.inputfieldTarget }

  showfieldform() {
    if (this.replytypeTarget.value === 'multiple_choice') {
      this.multiplechoiceTarget.classList.remove('hidden')
      const values = JSON.stringify(Array.from(this.multiplechoiceTarget.querySelectorAll('.value')).map((value) => value.innerText))
      this.inputField.value = values
    }
    else {
      this.multiplechoiceTarget.classList.add('hidden')
      this.extrachoicesTarget.innerHTML = ''
      this.existingchoicesTarget.innerHTML = ''
      const values = JSON.stringify(Array.from(this.multiplechoiceTarget.querySelectorAll('.value')).map((value) => value.innerText))
      this.inputField.value = values
    }
  }

  showBtn() {
    this.newchoiceTarget.value.length > 0
      ? this.buttonTarget.classList.remove('hidden')
      : this.buttonTarget.classList.add('hidden')
  }

  addValue() {
    const item = `
      <li>
				<span class='value'>${this.newchoiceTarget.value}</span>
				<i class='fas fa-trash text-danger' data-action='click->add-choices#remove'></i>
			</li>
    `
    this.extrachoicesTarget.insertAdjacentHTML('beforeend', item)

    this.newchoiceTarget.value = ''
    this.buttonTarget.classList.add('hidden')

    const values = JSON.stringify(Array.from(this.multiplechoiceTarget.querySelectorAll('.value')).map((value) => value.innerText))
    this.inputField.value = values

  }

  remove(event) {
    event.target.parentNode.remove()
    const values = JSON.stringify(Array.from(this.multiplechoiceTarget.querySelectorAll('.value')).map((value) => value.innerText))
    this.inputField.value = values
  }
}
