import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['deleteBtn']

  confirm(event) {
    event.preventDefault()
    this.deleteBtnTarget.classList.remove('text-danger')
    this.deleteBtnTarget.innerHTML = `
      <a class="btn btn-danger py-1 px-2" data-turbo-method="delete"
         href="${this.element.dataset.deleteUrl}?table_name=${this.element.dataset.tableName}">
        <i class="fa-solid fa-trash me-1 fa-xs"></i>${window.i18n.t('actions.confirm')}?
      </a>
    `
    this.deleteBtnTarget.removeAttribute('data-action')
  }

}
