import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = [
    'type',
    'start',
    'stop',
    'saveBtn',
    'deleteBtn'

  ]

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  get closeBtn() { return this.saveBtnTarget.closest('.modal').querySelector('.btn-close') }

  edit(event) {
    var offday_id = event.target.dataset.offday
    var start = this.startTarget.value
    var stop = this.stopTarget.value
    var type_id = this.typeTarget.value
    
    fetch(`/offdays/${offday_id}`, {
      method:  'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({ start: start, stop: stop, dayoff_type_id: type_id })

    })
      .then(response => response.json())
      .then(() => {
        if (this.closeBtn) {
          this.closeBtn.click()
        }
        if (window.calendar) {
          window.calendar.refetchEvents()
        }
      })
  }

  delete(event) {
    var offday_id = event.target.dataset.offday
   
    fetch(`/offdays/${offday_id}`, {
      method:  'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
      .then(response => response.json())
      .then(() => {
        if (this.closeBtn) {
          this.closeBtn.click()
        }
        if (window.calendar) {
          window.calendar.refetchEvents()
        }
      })
  }
}