import { Controller } from "@hotwired/stimulus"
// import { i18n } from '../../components/config/i18n.js'
import XLSX from 'xlsx'
export default class extends Controller {
	static targets = ["file_input", "importBtn", "table", "tableHeader", "tableData", "progress", "progressWrapper", "company", "importNumber", "deleteBtn", 'job']

  get csrfToken() { return document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content") }

  get jobName() { return this.jobTarget.getAttribute('data-job') }

  get jobFolder() { return this.jobTarget.getAttribute('data-folder') }

  get materialFields() {
    switch (this.jobName) {
      case 'ImportMaintenanceJob':
        this.delete_first = false
        return this.importMaintenanceJob
      default:
        return []
    }
  }

  get importMaintenanceJob() {
   return [
    {"field_name":'none',                        "translation":`${window.i18n.t('shared.ignore') }`, "required": false},
    {"field_name":'control_name',                "translation":`control_name`,                "required": true},
    {"field_name":'frequence',              "translation":`frequence`,              "required": true},
    {"field_name":'interval',                    "translation":`interval`,                    "required": true},
    {"field_name":'amenity_internal_identifier', "translation":`amenity_internal_identifier`, "required": true},
    {"field_name":'start_month',                 "translation":`start_month`,                 "required": true},
    {"field_name":'form_question',               "translation":`form_question`,               "required": true},
   ]
  }

 connect() {
  console.log("import_controller connected")
  console.log("jobName", this.jobName)
  console.log("materialFields", this.materialFields)
  }

  get requiredFields() {
    return this.materialFields.filter((field) => !!field['required'])
  }

  async prepare_import(event) {
    this.tableDataTarget.innerHTML = '';
    this.tableHeaderTarget.innerHTML = '';
    await this.read_file(event)

    const selectTr = document.createElement('tr');
    const headerTr = document.createElement('tr');
    Object.keys(this.json[0]).forEach((key) => {
      const selectTh = document.createElement('th');
      const fieldSelector = document.createElement('select');
      fieldSelector.classList.add('field-selector');
      fieldSelector.id =  `select-${key}`;
      this.materialFields.forEach((field) => {
        const opt = document.createElement('option')
        opt.value = field['field_name']
        opt.text = field['translation']
        fieldSelector.add(opt)
        selectTh.appendChild(fieldSelector)
      })

      selectTr.appendChild(selectTh);

      const th = document.createElement('th');
      th.innerHTML = key;
      headerTr.appendChild(th);
    })
    this.tableHeaderTarget.appendChild(selectTr);
    this.tableHeaderTarget.appendChild(headerTr);
    this.json.slice(0, 10).forEach((row) => {
      const tr = document.createElement('tr');
      Object.keys(this.json[0]).forEach((key) => {
        const td = document.createElement('td');

        td.innerHTML = row[key];
        tr.appendChild(td);
      })
      this.tableDataTarget.appendChild(tr);
    }
    )
  }

  async read_file(event) {
    const file = event.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    this.json = XLSX.utils.sheet_to_json(sheet);
  }

  import () {
    const ok_for_import = this.checkForRequiredFields()
    if (ok_for_import) {
      this.importData()
    }
    else {
      alert('Please select a field for each requiered column');
    }
  }

  checkForRequiredFields () {
    const selectFields = this.element.querySelectorAll('select')
    const fields = []
    selectFields.forEach((field) => {
      fields.push(field.value)
    })
    const requiredFieldList = this.requiredFields.map((field) => field['field_name'])
    requiredFieldList.forEach ((field) => {
      if (!fields.includes(field)) {
        return false;
      }
    })
    return true;
  }

  importData () {
    const selectFields = this.element.querySelectorAll('.field-selector')
    const fieldsMatching = []
    this.importBtnTarget.classList.add("hidden");
    selectFields.forEach((field) => {
      fieldsMatching.push({"table_field": field.value, "file_field": field.id.replace('select-','')})
    })
    const data = this.json
    console.log("fields_matching :",fieldsMatching)
    fetch ('/admin_import.json', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({job_folder: this.jobFolder, import_job: this.jobName, fields_matching: fieldsMatching, data: data, company_id: this.companyTarget.value, delete_first_line: this.delete_first})
    })
    .then(response => response.json())
    .then(data => {
      const jobId = data.job.job_id;
      const jobProviderId = data.job.job_provider_id;
      this.progressTarget.classList.remove("hidden");
      this.progressTarget.textContent = `${window.i18n.t('shared.processing')}`;
      this.timer = setInterval(() => {
        this.checkJobStatus(jobId, jobProviderId)
      }, 1000);
    });
  }

  checkJobStatus(jobId, jobProviderId) {
    fetch(`/import_status?job_id=${jobId}&job_provider_id=${jobProviderId}`)
      .then(response => response.json())
      .then(data => {
        const percentage = data.percentage;
        const importNumberValue = data.import_number;
        this.progressTarget.textContent = `${window.i18n.t('shared.processing')} ${percentage}%`;
        if(data.status == "error") {
            this.stopCheckJobStatus();
        }else if(data.status === "complete") {
          this.stopCheckJobStatus()
          this.progressTarget.textContent = `${window.i18n.t('shared.import_number')} ${importNumberValue} - ${window.i18n.t('shared.processing_completed')}`;
          this.deleteBtnTarget.classList.remove("hidden");
          this.deleteBtnTarget.href = `/imports/${importNumberValue}`
        }
      })
    }

    stopCheckJobStatus() {
      if(this.timer) {
        clearInterval(this.timer);
      }
  }

  disconnect() {
    this.stopCheckJobStatus();
  }

}
