import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['group', 'icon']

  toggleHidden() {
    this.hiddenData.forEach(item => item.classList.toggle('hidden'))
    this.iconTarget.classList.toggle('fa-chevron-right')
    this.iconTarget.classList.toggle('fa-chevron-down')
  }

  get hiddenData() {
    return this.groupTarget.querySelectorAll('li')
  }
}
