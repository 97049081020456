import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['field_row']

  edit(event) {
    const selected_value = event.target.dataset.value

    fetch(`/report_types/${this.report_type_id}`, {
      headers: {
        'Accept':       'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({
        report_type: {
          [this.field]: selected_value
        }
      }),
      method: 'PATCH',
    })
      .then(response => response.json())
      .then(_data => {
        Array.from(this.field_rowTarget.querySelectorAll('td.report_type_choice')).forEach((element) => {
          if (element.dataset.value == selected_value) {
            element.innerHTML = '<i class="fas fa-check-square text-primary"></i> '
          } else {
            element.innerHTML = `<i class="far fa-square" data-action='click->report-types--edit#edit' data-value=${element.dataset.value}></i> `
          }
        })
      })
  }

  get field()          { return this.field_rowTarget.dataset.field }
  get report_type_id() { return this.field_rowTarget.dataset.reportType }

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

}
